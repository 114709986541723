import React, { useState } from "react";
import classnames from "classnames";
import { Navbar, Container, NavItem, Button, Row, Col } from "reactstrap";
import { useFromUser } from "stores/LocalStorageHelper";
import ScreenRecorder from "../../features/screenRecorder/ScreenRecorder";
import ThemeToggle from "components/themeToggle/ThemeToggle";
import { NavLink, useLocation } from "react-router-dom";
import RecentMessages from "../../features/crm/components/RecentMessages";

/**
 * DefaultNavbar - A navigation bar component for the application.
 * @param {Object} props - Properties passed to the component.
 * @returns {JSX.Element} - Rendered component.
 */
const DefaultNavbar = ({ handleMiniClick }) => {
	const [collapseOpen] = useState(false);
	const name = useFromUser("firstName");
	const location = useLocation();

	/**
	 * Toggles the sidebar visibility on smaller devices.
	 */
	const toggleSidebar = () => {
		document.documentElement.classList.toggle("nav-open");
	};

	// Configuration object mapping paths to labels
	const navLinksConfig = {
		dashboard: "Dashboard",
		"deal-list": "Deal",
		"customer-list": "CRM",
		"inventory-list": "Inventory",
	};

	/**
	 * Renders the navigation links.
	 */
	const renderNavLinks = () => (
		<Row className="justify-content-center w-100">
			{Object.entries(navLinksConfig).map(([path, label]) => {
				const isActive = location.pathname === `/${path}`;
				return (
					<NavLink
						key={path}
						to={`/${path}`}
						className={classnames({
							"btn btn-surface btn-sm": !isActive,
							"btn btn-sm btn-surface active": isActive,
						})}
					>
						{label}
					</NavLink>
				);
			})}
		</Row>
	);

	/**
	 * Renders the right side items.
	 */
	const renderRightItems = () => (
		<>
			<NavItem
				className="d-none d-md-block"
				style={{ paddingTop: ".3rem", listStyle: "none" }}
			>
				<RecentMessages />
			</NavItem>
			<p
				className="d-none d-md-block"
				style={{ fontSize: "20px", color: "lightgray" }}
			>
				&nbsp; |&nbsp;
			</p>
			<NavItem
				className="d-none d-md-block"
				style={{ paddingTop: ".3rem", listStyle: "none" }}
			>
				<ScreenRecorder />
			</NavItem>
			<p
				className="d-none d-md-block"
				style={{ fontSize: "20px", color: "lightgray" }}
			>
				&nbsp; |&nbsp;
			</p>
			<NavItem
				className="d-none d-md-block"
				style={{ paddingTop: ".3rem", listStyle: "none" }}
			>
				<a
					className="btn btn-link btn-sm"
					href="http://connect.dealerclick.org/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="nc-icon nc-support-17" /> Remote Connect
				</a>
			</NavItem>
			<p
				className="d-none d-md-block"
				style={{ fontSize: "20px", color: "lightgray" }}
			>
				&nbsp; |&nbsp;
			</p>
			<div className="px-2">
				<ThemeToggle />
			</div>
		</>
	);

	return (
		<Navbar
			className="p-0 navbar-absolute bg-white customNav fixed-top"
			expand="lg"
			style={{ height: "50px" }}
		>
			<Container fluid className="pb-half">
				<Row className="navbar-wrapper w-100 align-items-center">
					<Col xs="6" md="4" className="d-flex align-items-center p-0">
						<div className="navbar-minimize">
							<Button
								className="btn btn-icon btn-round"
								color="link"
								id="minimizeSidebar"
								onClick={handleMiniClick}
							>
								<i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
								<i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
							</Button>
						</div>
						<div
							className={classnames("navbar-toggle", {
								toggled: collapseOpen,
							})}
						>
							<button
								className="navbar-toggler"
								type="button"
								onClick={toggleSidebar}
							>
								<span className="navbar-toggler-bar bar1" />
								<span className="navbar-toggler-bar bar2" />
								<span className="navbar-toggler-bar bar3" />
							</button>
						</div>
						<p className="m-0" style={{ fontSize: "20px" }}>
							Hi, {name}!
						</p>
					</Col>
					<Col md="4" className="d-none d-md-flex p-0">
						{renderNavLinks()}
					</Col>
					<Col
						xs="6"
						md="4"
						className="d-flex align-items-center justify-content-end p-0"
					>
						{renderRightItems()}
					</Col>
				</Row>
			</Container>
		</Navbar>
	);
};

export default DefaultNavbar;
