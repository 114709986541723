import React from "react";
import AttachmentModal from "features/attachments/AttachmentModal";
import Twilio from "services/twilioService/Twilio";
import shallow from "zustand/shallow";
import MailgunEmail from "services/mailgunService/MailgunEmail";
//import { TemplateVariableContext } from "context/TemplateVariableContext";
import NoteButton from "features/notes/NoteButton";
import useCurrentAccountStore from "./AccountStore";
import { saveAccountTable } from "./AccountAPI";
import { showApiError } from "../utils/errorRoutingUtils";
import { saveFailAlert } from "../utils/alertUtils";
import history from "utils/createBrowserHistory";
import useCurrentDealStore from "../stores/DealStore";
import { MessageZone, PATHS } from "../constants/Constants";
import useRoute from "../hooks/useRoute";
import InitializeTemplateVars from "../utils/initializeTemplateVars";
import { TemplateVariableProvider } from "../context/TemplateVariableContext";

const AccountActionBar = () => {
	const { account, buyer, editAccountNotes } = useCurrentAccountStore(
		(state) => state,
		shallow
	);

	const dealDetailPage = useRoute(PATHS.DEAL_DETAIL);

	// const { setRequestDocs } = useContext(TemplateVariableContext);

	// useEffect(() => {
	// 	setRequestDocs(true);
	// 	// eslint-disable-next-line
	// }, []);
	const saveNotes = () => {
		if (account.id != null) {
			let modifiedAccount = { ...account };
			const modifiedNotes =
				account.notes.length > 0 ? JSON.stringify(account.notes) : "";

			modifiedAccount.notes = modifiedNotes;
			saveAccountTable(modifiedAccount).then(
				(response) => {
					console.log(response);
					saveAccountTable(response.data.content);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
	};
	const { editID } = useCurrentDealStore((state) => state, shallow);
	// Gets the deal data and routes to detail page
	const getDealData = (id) => {
		editID(id);
		history.push(dealDetailPage, { ID: id });
		console.log("getData() has finished");
	};

	return (
		<div className="action-bar content">
			<TemplateVariableProvider>
				<InitializeTemplateVars messageZone={MessageZone.ACCOUNT}>
					<Twilio sendToNumber={buyer.cellPhone} personType={"account"} />
					<MailgunEmail customerEmail={buyer.email} />
				</InitializeTemplateVars>
			</TemplateVariableProvider>
			<AttachmentModal relatedID={account.id} type="account" />
			<NoteButton
				notes={account.notes}
				editNotes={editAccountNotes}
				saveNotes={saveNotes}
			/>
			<button
				className="btn btn-primary"
				onClick={() => getDealData(account.dealID)}
			>
				Deal Details
			</button>
		</div>
	);
};

export default AccountActionBar;
