import React, { useState } from "react";
import {
	Button,
	Label,
	Card,
	Col,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Form,
} from "reactstrap";
import {
	CurrencyInput,
	PercentInput,
	SelectorElement,
} from "../features/forms/components/InputElement";
import { saveSuccessAlert } from "../utils/alertUtils";
import formNavigationUtils from "utils/formNavigationUtils";
import useCurrentAccountStore from "./AccountStore";
import shallow from "zustand/shallow";
import AccountHook from "./AccountHook";
/**
 *
 */
const AccountSettings = () => {
	const { account, editAccount } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	const save = AccountHook();

	// Hooks for Account Setting store values
	const [latePercent, setLatePercent] = useState(account.lateChargePct);
	const [lateFixAmount, setLateFixAmount] = useState(account.lateChargeAmt);
	const [lateType, setLateType] = useState(account.lateChargeType);

	const commandsave = () => {
		account.lateChargePct = latePercent;
		account.lateChargeAmt = lateFixAmount;
		account.lateChargeType = lateType;
		editAccount(account);
		save();
		saveSuccessAlert();
	};

	return (
		<div onKeyDown={formNavigationUtils}>
			<Form>
				<Button
					color="success"
					onClick={() => {
						commandsave();
					}}
					size="md"
				>
					<i className="nc-icon nc-cloud-download-93"></i> Save
				</Button>

				<Card>
					<CardHeader>
						<CardTitle tag="h3">Account setting</CardTitle>
					</CardHeader>
					<CardBody>
						<Col>
							<Row className="mx-0 pb-2">
								<SelectorElement
									name="lateType"
									label="Late Charge by"
									colSize="col-sm-3"
									value={lateType}
									onChange={(e) => setLateType(e.target.value)}
									options={[
										{ value: -1, label: "Not Auto Calculate" },
										{ value: 0, label: "Pct of Pmt" },
										{ value: 2, label: "Pct of Past Due" },
										{ value: 1, label: "Flat Fee" },
									]}
									disableValidation
								/>
								<PercentInput
									label="Late Percentage"
									value={latePercent}
									onChange={setLatePercent}
									disableValidation
									colSize="col-sm-3"
								/>
								<Label> + </Label>
								<CurrencyInput
									value={lateFixAmount}
									onChange={setLateFixAmount}
									name="lateFixAmount"
									label="Fixed Amount"
									type="Currency"
									colSize="col-sm-3"
									disableValidation
								/>
							</Row>
						</Col>
					</CardBody>
				</Card>
			</Form>
		</div>
	);
};

export default AccountSettings;
