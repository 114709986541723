import React, { useEffect, useState } from "react";

import ChildrenModal from "../../../components/modals/ChildrenModal";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import "assets/css/recent-message-log.css";
import OpenModalButton from "../../deal/components/OpenModalButton";
import MessageHistory from "./MessageHistory";

dayjs.extend(isBetween);

const RecentMessages = () => {
	const [newModal, setNewModal] = useState(false);

	// Local storage
	const user = JSON.parse(sessionStorage.getItem("user"));
	const locationID = user?.locationID;

	const toggleNew = () => {
		setBlinking("btn-sm");
		setNewModal(!newModal);
	};

	const [blinking, setBlinking] = useState("btn-sm");

	useEffect(() => {
		//setBlinking("btn-sm");
		// eslint-disable-next-line
	}, [blinking, setBlinking]);
	useEffect(() => {
		//Set up WebSocket connection
		// const ws = new WebSocket(
		// 	"ws://8a18-104-182-221-130.ngrok-free.app/ws/messages"
		// );
		const ws = new WebSocket(
			`wss://dealerclick.net/dev/integrations/ws/messages?userId=${locationID}`
		);
		console.log("Connected to WebSocket");

		ws.onopen = () => {
			console.log("Connected to WebSocket");
		};

		ws.onmessage = (event) => {
			console.log("WebSocket message received:", event.data);
			setBlinking("blinking-button");
			//			setBlinking("btn-sm blinking-button");
			//getMsgHistory();
		};

		ws.onclose = () => {
			console.log("Disconnected from WebSocket");
		};

		ws.onerror = (error) => {
			console.error("WebSocket error:", error);
		};

		//setSocket(ws);
		return () => {
			if (ws) {
				ws.close();
			}
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<OpenModalButton
				buttonClass="blinking btn-sm"
				buttonColor="link"
				onClick={toggleNew}
				buttonText="Message History"
				iconClass="nc-icon nc-simple-add"
			/>
			<ChildrenModal
				modal={newModal}
				toggle={toggleNew}
				modalTitle="Message History"
				children={<MessageHistory />}
			/>
		</>
	);
};
export default RecentMessages;
