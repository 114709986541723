/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import ReactTable from "../components/tables/ReactTable";
import FilterColumn from "utils/filterColumn";
//import ReactTable from "ReactTable";

const AccountTable = ({ data }) => {
	const customerColumn = "customerDetails";
	const vehicleColumn = "vehicle";
	const vehicleHiddenColumns = ["Year", "Make", "Model", "Stock"];
	const customerHiddenColumns = ["Name", "Email", "Phone"];
	const hiddenColumns = [
		"Name",
		"Email",
		"Phone",
		"Stock",
		"Year",
		"Make",
		"Model",
	];
	console.log({ data });

	const Cell = (props) => (
		<div>
			<div>{props.row.original.fullName}</div>
			<div style={{ wordBreak: "break-all" }}>{props.row.original.email}</div>
			<NumberFormat
				value={props.row.original.phone}
				format="+1 (###) ###-####"
				isNumericString={true}
				displayType="text"
			/>
			{props.row.original.hasTrade && <div>Has Trades</div>}
			{props.row.original.coBuyerID > 0 && (
				<div>CoBuyer: {props.row.original.cofullName}</div>
			)}
		</div>
	);

	const vehicleCell = (props) => (
		<div>
			<div>{props.row.original.vehicle}</div>
			<div>
				{props.row.original.stock != null && (
					<div>Stock#: {props.row.original.stock}</div>
				)}
			</div>
			<div>
				{props.row.original.vin != null && (
					<div>VIN: {props.row.original.vin}</div>
				)}
			</div>
		</div>
	);

	const accountCell = (props) => (
		<div>
			<div>
				{props.row.original.loanDate != null && (
					<div>{dayjs(props.row.original.loanDate).format("MM/DD/YYYY")}</div>
				)}
			</div>
			<div>Account # {props.row.original.accountNumber}</div>
			<div>
				{props.row.original.term} payments of{" "}
				<NumberFormat
					value={props.row.original.payment}
					displayType={"text"}
					thousandSeparator={true}
					prefix={"$"}
				/>{" "}
				{props.row.original.loanPeriod}
			</div>
		</div>
	);
	const statusCell = (props) => <div>{props.row.original.statusLabel}</div>;

	return (
		<div>
			<Row>
				<Col md="12">
					<Card>
						<CardBody>
							<ReactTable
								// You can choose primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none = gray
								className="-striped -highlight primary-pagination"
								customerColumn={customerColumn}
								vehicleColumn={vehicleColumn}
								hiddenColumns={hiddenColumns}
								vehicleHiddenColumns={vehicleHiddenColumns}
								customerHiddenColumns={customerHiddenColumns}
								data={data}
								isDealTable={true}
								columns={[
									{
										Header: "Photo",
										accessor: "thumbnail",
										disableFilters: true,
										className: "vehicle-photo",
									},
									{
										id: "customerDetails",
										Header: "Customer",
										accessor: "customerDetails",
										style: { whiteSpace: "unset" },

										Cell: Cell,
										//To visible the customer dropdown sort set it back to true
										customerSort: false,
									},
									{
										id: "Name",
										Header: "Name",
										accessor: "fullName",
										style: { whiteSpace: "unset" },
										Cell: Cell,
										customerSort: false,
									},
									{
										id: "Phone",
										Header: "Phone",
										accessor: "phone",
										style: { whiteSpace: "unset" },
										Cell: Cell,
										customerSort: false,
									},
									{
										id: "Email",
										Header: "Email",
										accessor: "email",
										style: { whiteSpace: "unset" },
										Cell: Cell,
										customerSort: false,
									},
									{
										id: "vehicle",
										Header: "Vehicle",
										accessor: "vehicleDetails",
										style: { whiteSpace: "unset" },
										//to visible vehicle dropdown set it to false
										vehicleSort: false,
										Cell: vehicleCell,
									},
									{
										id: "Year",
										Header: "Year",
										accessor: "year",
										style: { whiteSpace: "unset" },
										vehicleSort: false,
										Cell: vehicleCell,
									},
									{
										id: "Make",
										Header: "Make",
										accessor: "make",
										style: { whiteSpace: "unset" },
										vehicleSort: false,
										Cell: vehicleCell,
									},
									{
										id: "Model",
										Header: "Model",
										accessor: "model",
										style: { whiteSpace: "unset" },
										vehicleSort: false,
										Cell: vehicleCell,
									},

									{
										id: "Stock",
										Header: "Stock",
										accessor: "stock",
										style: { whiteSpace: "unset" },
										vehicleSort: false,
										Cell: vehicleCell,
									},
									{
										Header: "Account",
										accessor: "account",
										style: { whiteSpace: "unset" },
										Cell: accountCell,
									},
									{
										Header: "status",
										accessor: "statusLabel",
										Filter: FilterColumn,
										filter: "includes",

										Cell: statusCell,
									},

									// {
									// 	Header: "Account",
									// 	accessor: "account",
									// 	Cell: accountCell,
									// 	Filter: ComboColumnFilter,
									// 	filter: (rows, id, filterValue) => {
									// 		const selectFilter = (row) => {
									// 			if (
									// 				filterValue.selectValue === "" ||
									// 				filterValue.selectValue === undefined
									// 			) {
									// 				return true;
									// 			}
									// 			return row.values[id] === filterValue.selectValue;
									// 		};
									// 		const textFilter = (row) => {
									// 			const rowValue = row.values[id];
									// 			return rowValue !== undefined
									// 				? String(rowValue)
									// 						.toLowerCase()
									// 						.startsWith(
									// 							String(filterValue.textValue).toLowerCase()
									// 						)
									// 				: true;
									// 		};
									// 		return rows.filter(
									// 			(row) => textFilter(row) && selectFilter(row)
									// 		);
									// 	},
									// },

									{
										Header: "Actions",
										accessor: "Actions",
										disableFilters: true,
									},
								]}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default AccountTable;
