import React from "react";
import logo from "assets/img/autocheck-logo.png";
import { getAutoCheck } from "api/DealerIntegrationApi";
import useBuildVehicle from "../../../hooks/useBuildVehicle";
import { Button, CardText } from "reactstrap";

export const AutoCheckButton = ({ inventoryID = null }) => {
	const getVehicle = useBuildVehicle();

	const commitData = () => {
		const vehicle = getVehicle();
		let id = null;
		if (inventoryID === null) {
			id = vehicle.inventoryID;
		} else {
			id = inventoryID;
		}
		console.log(id);
		getAutoCheck(id).then(
			(response) => {
				const file = new Blob([response.data], {
					type: "text/html",
				});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			},
			(error) => {
				console.log(error);
			}
		);
	};

	return (
		<div>
			<img src={logo} alt="AutoCheck" className="mb-3" />
			<CardText className="my-3">
				Access comprehensive vehicle history reports from AutoCheck, including auction data, reported odometer readings, and more.
			</CardText>
			<div className="mt-3">
				<Button
					onClick={commitData}
					className="historyButtons zoom btn-primary"
				>
					Get Report
				</Button>
			</div>
		</div>
	);
};
export default AutoCheckButton;
