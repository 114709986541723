import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, Table, Button } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentRecapStore from "stores/RecapStore";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import VehicleBreakdown from "./VehicleBreakdown";
import AftermarketBreakdown from "./AftermarketBreakdown";
import TradeBreakdown from "./TradeBreakdown";
import DownBreakdown from "./DownBreakdown";
import OfficialFeesBreakdown from "./OfficialFeesBreakdown";
import SellerFeesBreakdown from "./SellerFeesBreakdown";
import GapInsuranceBreakdown from "./GapInsuranceBreakdown";
import ServiceContractBreakdown from "./ServiceContractBreakdown";
import ChildrenModal from "components/modals/ChildrenModal";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

/**
 * RecapMainCard component to display a summary of the deal's financial breakdown.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered RecapMainCard component
 */
const RecapMainCard = () => {
	const {
		carProfit,
		aftmktProfit,
		totalTradeAllowance,
		totalTradeACV,
		totalTradeProfit,
		totalDown,
		miscCost,
		miscProfit,
		gapInsCost,
		gapInsProfit,
		warrantyCost,
		warrantyProfit,
		official,
		collisionTotCost,
		collisionTotProfit,
		creditLifeCost,
		disabCost,
		credLifeProfit,
		disabProfit,
	} = useCurrentRecapStore((state) => state, shallow);

	const {
		isAftMktProfit,
		isMiscProfit,
		isGapProfit,
		isServConProfit,
		totalCost,
	} = useCurrentDealStore(
		(state) => ({
			isAftMktProfit: state?.deal?.dealSettings?.profitSettings?.isAftMktProfit,
			isMiscProfit: state?.deal?.dealSettings?.profitSettings?.isMiscProfit,
			isGapProfit: state?.deal?.dealSettings?.profitSettings?.isGapProfit,
			isServConProfit:
				state?.deal?.dealSettings?.profitSettings?.isServConProfit,
			totalCost: state?.vehicle?.totalCost,
		}),
		shallow
	);

	const {
		price,
		aftmkt,
		aftmktCost,
		totalSellerFees,
		gap,
		warranty,
		collisionTotPremium,
		lifePremium,
		disabilityPremium,
	} = useCurrentSaleStore((state) => state, shallow);

	const rows = [
		{
			label: "Vehicle",
			amount: price,
			costVal: totalCost,
			profit: carProfit,
			component: <VehicleBreakdown />,
		},
		{
			label: "Aftermarket",
			amount: aftmkt,
			costVal: aftmktCost,
			profit: isAftMktProfit ? aftmktProfit : 0,
			component: <AftermarketBreakdown />,
		},
		{
			label: "Trade",
			amount: totalTradeACV,
			costVal: totalTradeAllowance,
			profit: totalTradeProfit,
			component: <TradeBreakdown />,
		},
		{
			label: "Down",
			amount: totalDown,
			costVal: 0,
			profit: 0,
			component: <DownBreakdown />,
		},
		{
			label: "Official Fees",
			amount: official,
			costVal: 0,
			profit: 0,
			component: <OfficialFeesBreakdown />,
		},
		{
			label: "Seller Fees",
			amount: totalSellerFees,
			costVal: miscCost,
			profit: isMiscProfit ? miscProfit : 0,
			component: <SellerFeesBreakdown />,
		},
		{
			label: "GAP/Ins.",
			amount: gap + collisionTotPremium + lifePremium + disabilityPremium,
			costVal: gapInsCost + collisionTotCost + creditLifeCost + disabCost,
			profit: isGapProfit
				? gapInsProfit + collisionTotProfit + credLifeProfit + disabProfit
				: 0,
			component: <GapInsuranceBreakdown />,
		},
		{
			label: "Service Contract",
			amount: warranty,
			costVal: warrantyCost,
			profit: isServConProfit ? warrantyProfit : 0,
			component: <ServiceContractBreakdown />,
		},
	];

	const [open, setOpen] = useState(false);
	const [modalContent, setModalContent] = useState(null);
	const [modalTitle, setModalTitle] = useState("");

	/**
	 * Toggles the modal with the given content and title.
	 * @param {ReactNode} content - The content to display in the modal.
	 * @param {string} title - The title of the modal.
	 */
	const toggle = (content, title) => {
		setOpen(!open);
		setModalContent(content);
		setModalTitle(title);
	};

	return (
		<Card>
			<CardHeader>
				<h3>Recap</h3>
			</CardHeader>
			<CardBody className="pb-0">
				<Table className="mb-0" responsive>
					<thead>
						<tr>
							<th>Item</th>
							<th>Sale Amount</th>
							<th>Cost</th>
							<th>Profit</th>
						</tr>
					</thead>
					<tbody>
						{rows.map((row, index) => (
							<tr key={index}>
								<td>
									<Button
										className="active px-1 btn-round btn-sm btn btn-outline-secondary w-100 my-0"
										onClick={() => toggle(row.component, row.label)}
									>
										{row.label}
									</Button>
								</td>
								<td>
									<CurrencyDisplayElement val={row.amount} />
								</td>
								<td>
									<CurrencyDisplayElement val={row.costVal} />
								</td>
								<td>
									<CurrencyDisplayElement val={row.profit} />
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<ChildrenModal
					modal={open}
					toggle={() => setOpen(!open)}
					size="md"
					modalTitle={modalTitle}
					children={modalContent}
				/>
			</CardBody>
		</Card>
	);
};

RecapMainCard.propTypes = {
	carProfit: PropTypes.number.isRequired,
	aftmktProfit: PropTypes.number.isRequired,
	totalTradeAllowance: PropTypes.number.isRequired,
	totalTradeACV: PropTypes.number.isRequired,
	totalTradeProfit: PropTypes.number.isRequired,
	totalDown: PropTypes.number.isRequired,
	miscCost: PropTypes.number.isRequired,
	miscProfit: PropTypes.number.isRequired,
	gapInsCost: PropTypes.number.isRequired,
	gapInsProfit: PropTypes.number.isRequired,
	warrantyCost: PropTypes.number.isRequired,
	warrantyProfit: PropTypes.number.isRequired,
	official: PropTypes.number.isRequired,
	collisionTotCost: PropTypes.number.isRequired,
	collisionTotProfit: PropTypes.number.isRequired,
	creditLifeCost: PropTypes.number.isRequired,
	disabCost: PropTypes.number.isRequired,
	credLifeProfit: PropTypes.number.isRequired,
	disabProfit: PropTypes.number.isRequired,
	isAftMktProfit: PropTypes.bool.isRequired,
	isMiscProfit: PropTypes.bool.isRequired,
	isGapProfit: PropTypes.bool.isRequired,
	isServConProfit: PropTypes.bool.isRequired,
	totalCost: PropTypes.number.isRequired,
	price: PropTypes.number.isRequired,
	aftmkt: PropTypes.number.isRequired,
	aftmktCost: PropTypes.number.isRequired,
	totalSellerFees: PropTypes.number.isRequired,
	gap: PropTypes.number.isRequired,
	warranty: PropTypes.number.isRequired,
	collisionTotPremium: PropTypes.number.isRequired,
	lifePremium: PropTypes.number.isRequired,
	disabilityPremium: PropTypes.number.isRequired,
};

export default RecapMainCard;
