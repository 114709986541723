import React from "react";

import logo from "assets/img/carfax-logo.png";
import useCarfaxUrl from "../hooks/useCarfaxUrl";
import useCurrentVehicleStore from "stores/InventoryStore";

import { confirmNewUrl } from "utils/alertUtils";
import {
	getCarfaxAccessToken,
	getCarfaxReport,
} from "api/DealerIntegrationApi";
import { successAlertCustom, failAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { useLocation } from "react-router";

import shallow from "zustand/shallow";
import { Button, CardText } from "reactstrap";

export const CarfaxReportButton = () => {
	const failAuthMessage = "Failed to get Authorization from Carfax.";

	// Hooks
	const url = useCarfaxUrl();
	const location = useLocation();

	// Local storage
	let user = JSON.parse(sessionStorage.getItem("user"));

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const carfaxState = decodeURIComponent(user["cfState"]);

	// URL query parameters returned from logging in to Carfax
	const params = new URLSearchParams(location.search);
	const queryParamState = params.get("state");
	const queryParamCode = params.get("code");

	const { vin } = useCurrentVehicleStore(
		(state) => ({
			vin: state.vin,
		}),
		shallow
	);

	// Gets the Carfax report from the database, it is doesn't exist, use the
	// access token stored in the database and generate a new report
	const getReport = () => {
		console.log("VIN: " + vin);
		getCarfaxReport(dealerID, locationID, vin).then(
			(res) => {
				const reportLink = res.data.content.carfaxLink;

				if (reportLink != null) {
					window.open(reportLink, "_blank");
				} else {
					failAlert("Failed to retrieve report.");
				}
			},
			(err) => checkValidToken(err)
		);
	};

	// After users authorize this app by logging in to their account, use the code returned
	//  in the query to request an access token
	const getAccessToken = () => {
		getCarfaxAccessToken(locationID, queryParamCode).then(
			(res) => {
				successAlertCustom(
					"Successfully logged in to Carfax. New reports can now be pulled."
				);
			},
			(e) => handleAccessTokenError(e)
		);
	};

	// Calls API to get the Carfax report. If user is not authenticated, routes
	// them to Carfax to authenticate
	const checkValidToken = (err) => {
		if (err?.response?.status === 401) {
			if (queryParamState != null && queryParamCode != null) {
				if (carfaxState !== queryParamState) {
					window.location.assign(url);
				} else {
					getAccessToken();
				}
			} else {
				confirmNewUrl(url);
			}
		} else {
			failAlert(err?.response?.data?.message || "Failed to get CarFax report");
		}
	};
	// If the code in the URL is invalid, try having users logging in to Carfax and
	// authorizing once again
	const handleAccessTokenError = (err) => {
		const message = err?.response?.data?.message;
		const startIndex = message?.indexOf("[");

		if (startIndex == null || startIndex === -1) {
			failAlert(message || failAuthMessage);

			return;
		}

		const arr = JSON.parse(message.slice(startIndex));

		if (arr[0].error === "invalid_grant") {
			window.location.assign(url);
		} else {
			failAlert(failAuthMessage);
		}
	};

	return (
		<div className="d-flex flex-column" style={{justifyContent: "space-between"}}>
			<div>
				<img src={logo} alt="Carfax" />
				<CardText className="my-3">
					Access comprehensive vehicle history reports from AutoCheck, including auction data, reported odometer readings, and more.
				</CardText>
			</div>

			<div className="mt-3">
				<Button
					onClick={getReport}
					className="historyButtons zoom btn-primary"
				>
					Get Report
				</Button>
			</div>
		</div>
	);
};

export default CarfaxReportButton;
