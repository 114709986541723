import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import formNavigationUtils from "../../../../../utils/formNavigationUtils";
import InputWrapper from "../../../../forms/components/InputWrapper";
import InputElement, {
	CheckBoxElement,
	IntegerInputElement,
	InventoryEditableSelectorElement,
	SelectorElement,
} from "../../../../forms/components/InputElement";
import {
	ActualMileageFlagOption,
	conditionOptions,
	driveType,
	fuelTypeList,
	newOrUsed,
	tireTypes,
	vehicleCategories,
	vehicleStatus,
	vehicleSubCategories,
	vehicleTypeOptions,
	vehicleTypes,
} from "../../../../../constants/Constants";
import useCurrentVehicleStore from "../../../../../stores/InventoryStore";
import shallow from "zustand/shallow";
import { useFormContext } from "react-hook-form";
import { useFromUser } from "../../../../../stores/LocalStorageHelper";
import { saveMake, saveModel } from "../../../../../api/InventoryAPI";
import { failAlert, saveFailAlert } from "../../../../../utils/alertUtils";
import { showApiError } from "../../../../../utils/errorRoutingUtils";

const GeneralInfo = () => {
	// Vehicle store
	const {
		stockNo,
		vin,
		year,
		make,
		model,
		trim,
		color,
		intColor,
		condition,
		transmission,
		doors,
		engine,
		mPGHwy,
		mPGCity,
		weight,
		type,
		newUsed,
		subModel,
		styleList,
		colorList,
		intColorList,
		status,
		numCylinders,
		makeList,
		modelList,
		driveTrain,
		fuelType,
		actualMileageFlag,
		axelCount,
		odometerIn,
		category,
		subCategory,
		height,
		length,
		tireSize,
		tireType,
		tireStatus,
		pullWeight,
		sleeper,
		sleeperSize,
		sleeperBeds,
		cabStyle,
		hiRoof,
		isFetTaxable,
		editHiRoof,
		editCabStyle,
		editSleeperBeds,
		editSleeperSize,
		editSleeper,
		editTireStatus,
		editPullWeight,
		editTireType,
		editTireSize,
		editLength,
		editHeight,
		editOdometerIn,
		editAxelCount,
		editFuelType,
		editDriveTrain,
		editStockNo,
		editVin,
		editYear,
		editMake,
		editModel,
		editTrim,
		editColor,
		editIntColor,
		editCondition,
		editTransmission,
		editDoors,
		editEngine,
		editMpgHwy,
		editMpgCity,
		editWeight,
		editType,
		editNewUsed,
		editSubModel,
		editNumCylinders,
		editActualMileageFlag,
		editCategory,
		editSubCategory,
		editMakeList,
		editModelList,
		editIsFetTaxable,
	} = useCurrentVehicleStore((state) => state, shallow);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const [makeLabel, setMakeLabel] = useState("Make");
	const [modelLabel, setModelLabel] = useState("Model");
	const [styleLabel, setStyleLabel] = useState("Style");
	const [trimLabel, setTrimLabel] = useState("Trim");

	const [yearList, setYearList] = useState([]);
	// Define custom error messages for different error types
	const methods = useFormContext();
	// Shows a list of all the exterior colors taken from VIN decoder, needs style
	const hasColor = () =>
		(colorList != null && colorList.length !== 0 && (
			<SelectorElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				name="color"
				label="Exterior Color"
				value={color}
				onChange={(e) => editColor(e.target.value)}
				options={createColorList(colorList)}
			/>
		)) || (
			<InputElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				value={color}
				name="color"
				label="Exterior Color"
				type="text"
				onChange={(e) => editColor(e.target.value)}
			/>
		);

	// Shows a list of all the interior colors taken from VIN decoder, needs style
	const hasIntColor = () =>
		(intColorList != null && intColorList.length !== 0 && (
			<SelectorElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				name="intColor"
				label="Interior Color"
				value={intColor}
				onChange={(e) => editIntColor(e.target.value)}
				options={createColorList(intColorList)}
			/>
		)) || (
			<InputElement
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
				{...methods}
				value={intColor}
				name="intColor"
				label="Interior Color"
				type="text"
				onChange={(e) => editIntColor(e.target.value)}
			/>
		);
	const createColorList = (colorLists) => {
		let colorList = [];

		//console.log(colorLists);
		colorLists.forEach((color) => {
			colorList.push({
				label: color,
				value: color,
			});
		});
		//console.log(colorList);
		return colorList;
	};

	// Shows a list of all the styles taken from VIN decode and changes vehicle
	// details based on style selected or show the already saved style from DB
	// const hasStyle = () =>
	//  (styleList != null && styleList.length !== 0 && (
	//      <SelectorElement
	//          readOnly={
	//              status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
	//          }
	//          {...methods}
	//          name="style"
	//          label={styleLabel}
	//          value={subModel}
	//          onChange={(e) => editSubModel(e.target.value)}
	//          options={styleList}
	//      />
	//  )) || (
	//      <InputElement
	//          readOnly={
	//              status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
	//          }
	//          {...methods}
	//          value={subModel}
	//          name="style"
	//          label={styleLabel}
	//          type="text"
	//          onChange={(e) => editSubModel(e.target.value)}
	//      />
	//  );

	const createMake = (m) => {
		const request = { make: m, locationID, dealerID };
		saveMake(request).then(
			(response) => {
				let makes = JSON.parse(sessionStorage.getItem("makes"));
				const newMake = response.data.content;
				makes.push({ label: m, value: m, obj: newMake });
				editMakeList(makes);
				sessionStorage.setItem("makes", JSON.stringify(makes));
				editMake(m);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const createModel = (mo, ma) => {
		console.log(make);
		console.log(ma);
		const makes = JSON.parse(sessionStorage.getItem("makes"));
		const makeID = makes.find((m) => m.obj.make === ma);
		if (!makeID) {
			failAlert("must select a make");
			return;
		}
		const request = {
			makeID: makeID.obj.id,
			model: mo,
			vehicleType: type,
			locationID,
			dealerID,
		};
		saveModel(request).then(
			(response) => {
				let models = JSON.parse(sessionStorage.getItem("models"));
				const newModel = response.data.content;
				models.push({ label: mo, value: mo, obj: newModel });
				editModelList(models);
				sessionStorage.setItem("models", JSON.stringify(models));
				editModel(mo);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	//generate year list
	useEffect(() => {
		const currentYear = new Date().getFullYear() + 1;
		const yearStart = 1900;
		const getYearList = [];
		for (let i = currentYear; i >= yearStart; i--) {
			getYearList.push({ label: i.toString(), value: i.toString() });
		}
		setYearList(getYearList);
	}, []);
	useEffect(() => {
		if (type === "RV") {
			setMakeLabel("Manufacturer");
			setModelLabel("Brand");
			setStyleLabel("Model");
			setTrimLabel("Class");
		} else {
			setMakeLabel("Make");
			setModelLabel("Model");
			setStyleLabel("Style");
			setTrimLabel("Trim");
		}
	}, [type]);

	// Update these handlers to handle both event objects and direct values
	const handleInputChange = (setter) => (e) => {
		const value = e && e.target ? e.target.value : e;
		setter(value);
	};

	return (
		<Form onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle="General"
				inputComponents={[
					<InputElement
						{...methods}
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						value={stockNo}
						name="stockNo"
						label="Stock Number"
						type="text"
						onChange={(e) => editStockNo(e.target.value)}
					/>,
					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						name="type"
						label="Vehicle Type"
						value={type}
						onChange={(e) => editType(e.target.value)}
						options={vehicleTypeOptions}
					/>,
					<InventoryEditableSelectorElement
						{...methods}
						disable={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						value={year}
						name="year"
						label="Year"
						type="number"
						onChange={(e) => {
							editYear(e);
						}}
						defaultValue={year}
						options={yearList}
						key={year}
					/>,
					<InventoryEditableSelectorElement
						disable={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						label={makeLabel}
						value={make}
						onChange={(e) => editMake(e)}
						options={makeList}
						name="make"
						key={make}
						defaultValue={make}
						onCreateOption={createMake}
					/>,
					<InventoryEditableSelectorElement
						disable={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						label={modelLabel}
						value={model}
						onChange={(e) => editModel(e)}
						options={modelList}
						name="model"
						key={model}
						defaultValue={model}
						onCreateOption={(e) => createModel(e, make)}
					/>,

					<SelectorElement
						{...methods}
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						name="newUsed"
						label="New or Used"
						value={newUsed || "Used"}
						onChange={(e) => editNewUsed(e.target.value)}
						options={newOrUsed}
					/>,

					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={vin}
						name="vin"
						label="VIN(SerialNo)"
						type="text"
						onChange={(e) => {
							editVin(e.target.value);
						}}
					/>,
					// hasStyle(),
					(styleList != null && styleList.length !== 0 && (
						<SelectorElement
							readOnly={
								status === vehicleStatus.SOLD ||
								status === vehicleStatus.DELETED
							}
							{...methods}
							label={styleLabel}
							value={subModel}
							name="style"
							onChange={(e) => editSubModel(e.target.value)}
							options={styleList}
						/>
					)) || (
						<InputElement
							readOnly={
								status === vehicleStatus.SOLD ||
								status === vehicleStatus.DELETED
							}
							{...methods}
							label={styleLabel}
							value={subModel}
							name="style"
							type="text"
							onChange={(e) => editSubModel(e.target.value)}
						/>
					),

					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						label={trimLabel}
						value={trim}
						name="trim"
						type="text"
						onChange={(e) => {
							editTrim(e.target.value);
						}}
					/>,
					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={driveTrain}
						name="DriveType"
						label="Drive Type"
						type="text"
						onChange={(e) => {
							editDriveTrain(e.target.value);
						}}
						options={driveType}
					/>,

					type !== "Trailer"
						? [
								<SelectorElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									name="fuelType"
									label="Fuel Type"
									value={fuelType}
									onChange={(e) => {
										editFuelType(e.target.value);
									}}
									options={fuelTypeList}
								/>,

								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={numCylinders}
									name="numCylinders"
									label="Cylinders"
									type="number"
									onChange={(e) => {
										editNumCylinders(e.target.value);
									}}
								/>,
								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={engine}
									name="engine"
									label="Engine"
									type="text"
									onChange={(e) => {
										editEngine(e.target.value);
									}}
								/>,

								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={transmission}
									name="transmission"
									label="Transmission"
									type="text"
									onChange={(e) => {
										editTransmission(e.target.value);
									}}
								/>,
								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={doors}
									name="doors"
									label="Doors"
									type="number"
									onChange={(e) => {
										editDoors(e.target.value);
									}}
								/>,

								<SelectorElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									name="condition"
									label="Condition"
									value={condition}
									onChange={(e) => editCondition(e.target.value)}
									options={conditionOptions}
								/>,
								<SelectorElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									name="actualMileage"
									label="Actual Mileage"
									value={actualMileageFlag}
									onChange={(e) => editActualMileageFlag(e.target.value)}
									options={ActualMileageFlagOption}
								/>,
								<IntegerInputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={odometerIn}
									name="odometerIn"
									label="Milage In"
									type="number"
									onChange={(e) => {
										editOdometerIn(e);
									}}
								/>,

								hasColor(),
								hasIntColor(),

								<IntegerInputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={mPGHwy}
									name="mPGHwy"
									label="MPG HWY"
									type="number"
									onChange={(e) => {
										editMpgHwy(e);
									}}
								/>,
								<IntegerInputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={mPGCity}
									name="mPGCity"
									label="MPG City"
									type="number"
									onChange={(e) => {
										editMpgCity(e);
									}}
								/>,
								<IntegerInputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={axelCount}
									name="axelCount"
									label="Axel Count"
									type="number"
									onChange={(value) => {
										editAxelCount(value);
									}}
								/>,
								<IntegerInputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={weight}
									name="weight"
									label="Weight (lbs)"
									type="text"
									onChange={(e) => {
										editWeight(e);
									}}
								/>,
						  ]
						: [
								<IntegerInputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={axelCount}
									name="axelCount"
									label="Axel Count"
									type="number"
									onChange={(e) => {
										editAxelCount(e.target.value);
									}}
								/>,
								<IntegerInputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={weight}
									name="weight"
									label="Curb Weight (lbs)"
									type="text"
									onChange={(e) => {
										editWeight(e);
									}}
								/>,
								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={height}
									name="height"
									label="Height"
									type="text"
									onChange={(e) => {
										editHeight(e);
									}}
								/>,
								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={length}
									name="length "
									label="length "
									type="text"
									onChange={(e) => {
										editLength(e);
									}}
								/>,
								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={tireSize}
									name="tireSize"
									label="Tire Size"
									type="text"
									onChange={(e) => {
										editTireSize(e.target.value);
									}}
								/>,
								<SelectorElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									name="type"
									label="Tire Type"
									value={tireType}
									onChange={(e) => editTireType(e.target.value)}
									options={tireTypes}
								/>,
								<SelectorElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									name="tireStatus"
									label="Tire Status"
									value={tireStatus}
									onChange={(e) => editTireStatus(e.target.value)}
									options={newOrUsed}
								/>,

								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={pullWeight}
									name="PullWeight"
									label="Towing Capacity"
									type="number"
									onChange={(e) => {
										editPullWeight(e);
									}}
								/>,
								//hasColor(),
								<SelectorElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									name="category"
									label="Category"
									value={category}
									onChange={(e) => editCategory(e.target.value)}
									options={vehicleCategories}
								/>,
								<SelectorElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									name="subCategory"
									label="Subcategory"
									value={subCategory}
									onChange={(e) => editSubCategory(e.target.value)}
									options={vehicleSubCategories}
								/>,
								<CheckBoxElement
									disableValidation
									checked={isFetTaxable}
									onChange={() => {
										editIsFetTaxable(!isFetTaxable);
									}}
									name="isFetTaxable"
									label="Has FET Tax"
									//colSize="col-sm-3"
									labelClass="pl-4"
								/>,
						  ],
					vehicleTypes.includes(type?.toUpperCase())
						? [
								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={sleeperBeds}
									name="sleeperBeds"
									label="Sleeper Beds"
									type="number"
									onChange={(e) => {
										editSleeperBeds(e.target.value);
									}}
								/>,
								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={sleeperSize}
									name="sleeperSize"
									label="Sleeper Size"
									type="text"
									onChange={(e) => {
										editSleeperSize(e.target.value);
									}}
								/>,
								<InputElement
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									{...methods}
									value={cabStyle}
									name="cabStyle"
									label="Cab Style"
									type="text"
									onChange={(e) => {
										editCabStyle(e.target.value);
									}}
								/>,
								<CheckBoxElement
									disableValidation
									checked={sleeper}
									onChange={() => {
										editSleeper(!sleeper);
									}}
									name="sleeper"
									label="Sleeper"
									//colSize="col-sm-3"
									labelClass="pl-4"
								/>,
								<CheckBoxElement
									disableValidation
									checked={hiRoof}
									onChange={() => {
										editHiRoof(!hiRoof);
									}}
									name="hiRoof"
									label="HiRoof"
									//colSize="col-sm-3"
									labelClass="pl-4"
								/>,
						  ]
						: [],
				]}
			/>
		</Form>
	);
};

export default GeneralInfo;
