import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Col,
	Row,
	Card,
	Button,
	CardBody,
	CardTitle,
	CardHeader,
} from "reactstrap";
import { showApiError } from "utils/errorRoutingUtils";
import {
	failAlert,
	retrieveFailAlert,
	twoOptionsAlert,
} from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import useCurrentDealStore from "stores/DealStore";
import dayjs from "dayjs";
import shallow from "zustand/shallow";
import fairfaxlogo from "assets/img/fairfaxsoftware-logo.png";
import { getFairfax, submitFairfax } from "api/FairFaxAPI";
import { saveVehicle } from "api/InventoryAPI";

const FairFaxTab = () => {
	const { ID, license, editVehicle, vehicle } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			license: state?.vehicle?.license,
			editVehicle: state.editVehicle,
			vehicle: state.vehicle,
		}),
		shallow
	);
	const [ros, setRos] = useState("");
	const [tag, setTag] = useState("");
	const [rosNumber, setRosNumber] = useState("");
	const [tlpNumber, setTlpNumber] = useState("");
	const [expirationDate, setExpirationDate] = useState("");
	const locationID = useFromUser("locationID");

	const handleContinue = (ROS) => {
		const inventoryID = vehicle?.inventoryID;
		if (inventoryID) {
			const updatedVehicle = { ...vehicle, reportOfSale: ROS };
			const request = {
				vehicle: updatedVehicle,
				kbb: { dcTableKbb: null },
			};
			saveVehicle(request).then(
				(res) => {
					editVehicle(res.data.content.vehicle);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	const commitData = () => {
		if (ID > 0) {
			getFairfax(ID).then(
				(response) => {
					const data = response.data.content;
					setRos(data?.ros || "");
					setTag(data?.tag || "");
					setRosNumber(data?.rosNumber || "");
					setTlpNumber(data?.tlpNumber || "");
					setExpirationDate(data?.expirationDate || "");
				},
				(error) => console.log(error)
			);
		}
	};

	useEffect(() => {
		commitData();
		// eslint-disable-next-line
	}, [ID]);

	const openInNewTab = (url) => {
		if (url) {
			const newWindow = window.open(url, "_blank", "noopener,noreferrer");
			if (newWindow) newWindow.opener = null;
		}
	};

	const submitData = () => {
		twoOptionsAlert("Do you want to Submit a new request?", "Yes", "No").then(
			(res) => {
				if (res === "Yes") {
					submitFairfax(locationID, ID).then(
						(response) => {
							openInNewTab(response.data.content.ros);
							openInNewTab(response.data.content.tag);
							commitData();
							handleContinue(response.data.content.rosNumber);
						},
						(err) => {
							failAlert(err.response.data.message);
						}
					);
				}
			}
		);
	};

	if (!ros && !tag && !rosNumber && !tlpNumber && !expirationDate) {
		return (
			<Col>
				<Col>
					<Button
						size="sm"
						type="submit"
						onClick={submitData}
						className="btn-primary mb-2"
					>
						<i className="nc-icon nc-cloud-upload-94" /> Submit Application
					</Button>
				</Col>
				<h5 className="my-3 text-center">No tags for this deal.</h5>
			</Col>
		);
	}

	return (
		<div className="col-12">
			<Card>
				<CardHeader>
					<CardTitle tag="h3">
						<img alt="Fairfax Logo" className="lenderImg" src={fairfaxlogo} />
					</CardTitle>
				</CardHeader>
				<CardBody>
					<Row>
						<Col md="3" className="text-center">
							<h6>License Plate</h6>
							{license}
						</Col>
						<Col md="3" className="text-center">
							<h6>ROS Number</h6>
							{rosNumber}
						</Col>
						<Col md="3" className="text-center">
							<h6>TLP Number</h6>
							{tlpNumber}
						</Col>
						<Col md="3" className="text-center">
							<h6>Expiration Date</h6>
							{expirationDate ? dayjs(expirationDate).format("MM/DD/YYYY") : ""}
						</Col>
					</Row>
					<Col className="text-center mt-3">
						<hr className="my-2 w-100" />
						<Button
							size="md"
							type="submit"
							onClick={submitData}
							className="btn-primary mb-2"
						>
							<i className="nc-icon nc-cloud-upload-94" /> Submit Application
						</Button>
					</Col>
				</CardBody>
			</Card>
		</div>
	);
};

FairFaxTab.propTypes = {
	ID: PropTypes.number,
	license: PropTypes.string,
	editVehicle: PropTypes.func,
	vehicle: PropTypes.object,
	locationID: PropTypes.string,
};

export default FairFaxTab;
