import React from "react";
import { Card, Col, Row } from "reactstrap";

import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import AccountCustomerHeader from "./AccountCustomerHeader";

import AccountSummary from "./AccountSummary";
import AccountInventoryHeader from "./AccountInventoryHeader";
import AccountBalance from "./AccountBalance";
import AccountPayoff from "./AccountPayoff";

export const AccountContent = () => {
	const { buyer, account } = useCurrentAccountStore((state) => state, shallow);

	console.log(buyer);
	console.log(account);
	return (
		<Row>
			<Row className="">
				<div className="col-md-4">
					<Col className="text-center px-0">
						<AccountCustomerHeader />
					</Col>
					<Card className="pb-2">
						<AccountInventoryHeader />
					</Card>
				</div>
				<div className="col-md-4">
					<Card className="py-2">
						<AccountSummary />
					</Card>
				</div>
				<div className="col-md-4">
					<Card>
						<AccountBalance />
					</Card>
				</div>
			</Row>
			<Row className="pt-2">
				<AccountPayoff />
			</Row>
		</Row>
	);
};
