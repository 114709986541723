import { yupResolver } from "@hookform/resolvers";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
	Card,
	CardBody,
	Col,
	CardTitle,
	CardHeader,
	Row,
	Collapse,
} from "reactstrap";
import { object, string } from "yup";
import { CheckBoxElement } from "../../../../forms/components/InputElement";
import { KbbContext } from "./KbbContext";
import { setEquipObj } from "./kbbHelperFunctions";

const KbbEquipmentSection = () => {
	// Local state
	const [open, setOpen] = useState(true);

	// KBB context
	const { dcTableKbbEquipment, setDcTableKbbEquipment } = useContext(
		KbbContext
	);
	console.log(dcTableKbbEquipment);

	// Define rules for each input field
	const schema = object().shape({
		attachmentCategory: string().required("Required"),
	});
	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	// Add the selected option into the array or remove it if it already contains it
	const optionsSelected = (obj) => {
		setDcTableKbbEquipment((prev) => {
			for (let i = 0; i < prev.length; i++) {
				if (prev[i].vehicleOptionID === obj.vehicleOptionID) {
					prev[i].isSelected = !prev[i].isSelected;
					return prev;
				}
			}
			return prev;
		});
	};

	const buildEquipObj = (vehicleOptions) => {
		let equipDisplay = {};
		vehicleOptions.forEach((obj) => {
			let key = obj.categoryGroup;
			let value = {
				...obj,
			};
			equipDisplay = setEquipObj(equipDisplay, key, value);
		});
		return equipDisplay;
	};
	const equipDisplay = buildEquipObj(dcTableKbbEquipment);

	const list = Object.keys(equipDisplay).map((category, catIndex) => (
		<React.Fragment key={catIndex}>
			<br />
			<h6>{category}</h6>
			<Row>
				{equipDisplay[category].map((obj) => {
					return (
						<CheckBoxElement
							key={obj.id || obj.equip} 
							{...methods}
							checked={obj.isSelected}
							onChange={() => optionsSelected(obj)}
							name={obj.equip}
							label={obj.equip}
							labelClass="pl-4"
						/>
					);
				})}
			</Row>
		</React.Fragment>
	));
	

	return (
			<Card>
				<CardHeader
					onClick={() => {
						setOpen((prev) => !prev);
					}}
				>
					<CardTitle tag="h3">KBB Optional Equipment</CardTitle>
					<i className="nc-icon nc-minimal-down" />
				</CardHeader>
				<CardBody>
					<Collapse role="tabpanel" isOpen={open}>
						<Row>
							<Col>
								<CardBody>
									{(list != null && list.length > 0 && list) || (
										<p>
											No optional equipment information found in KBB for this
											vehicle
										</p>
									)}
								</CardBody>
							</Col>
						</Row>
					</Collapse>
				</CardBody>
			</Card>
	);
};

export default KbbEquipmentSection;
