import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import Recap from "../subFeatures/recap/Recap";
import ReviewWrapper from "../subFeatures/review/ReviewWrapper";
import ChildrenModal from "components/modals/ChildrenModal";
import {
	DeleteButton,
	SaveButton,
	TransferToBHPHButton,
	TransferToFundedButton,
	TransferToSoldButton,
	UnwindButton,
} from "../subFeatures/button/DealButton";
import AttachmentModal from "features/attachments/AttachmentModal";
import Twilio from "services/twilioService/Twilio";
import MailgunEmail from "services/mailgunService/MailgunEmail";
import NotesButton from "../subFeatures/notes/NotesButton";
import ActionBar from "components/ActionBar";
import VoidButton from "../subFeatures/voidRepoStatus/VoidButton";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { DealStatus } from "constants/Constants";
import useIsOwnerOrAdmin from "features/settings/subFeatures/settingsTabs/userManagement/useIsOwnerOrAdmin";
import { TemplateVariableContext } from "context/TemplateVariableContext";
import AutoSaveWrapper from "./AutoSaveWrapper";

/**
 * DealActionBar Component to manage deal-related actions.
 *
 * @component
 * @returns {JSX.Element} The rendered DealActionBar component
 */
const DealActionBar = () => {
	const [showRecap, setShowRecap] = useState(false);
	const [showReview, setShowReview] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
	const [actionsModal, setActionsModal] = useState(false);

	const isOwnerOrAdmin = useIsOwnerOrAdmin();
	const { setRequestDocs } = useContext(TemplateVariableContext);
	const toggleRecap = () => setShowRecap(!showRecap);
	const toggleReview = () => setShowReview(!showReview);
	const toggleActionsModal = () => setActionsModal(!actionsModal);
	const {
		type,
		ID,
		email,
		lender,
		loanPeriod,
		customerPhoneNumber,
	} = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			ID: state.deal.ID,
			email: state.buyer.email,
			lender: state.lender,
			loanPeriod: state.deal.loanPeriod,
			customerPhoneNumber: state.buyer.cellPhone,
		}),
		shallow
	);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 1000);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		setRequestDocs(true);
	}, [setRequestDocs]);

	return (
		<>
			<ActionBar
				isMobile={isMobile}
				toggleActionsModal={toggleActionsModal}
				actionsModal={actionsModal}
				modalTitle="Deal Actions"
			>
				<Button className="btn btn-info btn-md" onClick={toggleReview}>
					<i className="nc-icon nc-single-copy-04"></i> Review
				</Button>
				<ChildrenModal
					size="xl"
					modal={showReview}
					toggle={toggleReview}
					modalTitle="Deal Review"
					children={<ReviewWrapper toggle={toggleReview} />}
				/>
				<Button className="btn-info btn-md" onClick={toggleRecap}>
					<i className="nc-icon nc-single-copy-04"></i> Recap
				</Button>
				<AutoSaveWrapper>
					<Twilio sendToNumber={customerPhoneNumber} dealID={ID} />
				</AutoSaveWrapper>
				<AutoSaveWrapper>
					<MailgunEmail customerEmail={email} />
				</AutoSaveWrapper>
				<ChildrenModal
					size="xl"
					modalTitle="Recap"
					modal={showRecap}
					toggle={toggleRecap}
					children={<Recap />}
				/>
				{type === DealStatus.FUNDED && isOwnerOrAdmin && <VoidButton />}
				{type === DealStatus.SOLD && <UnwindButton />}
				{type === DealStatus.PENDING && <TransferToSoldButton />}
				{type === DealStatus.SOLD && !lender?.inHouse && (
					<TransferToFundedButton />
				)}
				{type === DealStatus.SOLD && lender?.inHouse && loanPeriod !== 5 && (
					<TransferToBHPHButton />
				)}
				<AttachmentModal relatedID={ID} type="deal" />
				<NotesButton />
				<DeleteButton />
			</ActionBar>
			<SaveButton />
		</>
	);
};

DealActionBar.propTypes = {
	type: PropTypes.string.isRequired,
	ID: PropTypes.string.isRequired,
	email: PropTypes.string,
	lender: PropTypes.object,
	loanPeriod: PropTypes.number,
	customerPhoneNumber: PropTypes.string,
};

export default DealActionBar;
