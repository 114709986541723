import React, { useState } from "react";
import { Row } from "reactstrap";
import shallow from "zustand/shallow";

import { DealStatus } from "constants/Constants";
import useCurrentDealStore from "stores/DealStore";
import DealInventoryListData from "./DealInventoryListData";
import InventoryDetailsModal from "./InventoryDetailsModal";

import ChildrenModal from "components/modals/ChildrenModal";
import OpenModalButton from "features/deal/components/OpenModalButton";
const InventoryListModal = () => {
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);

	const toggleAdd = () => {
		setAddModal(!addModal);
	};

	const toggleEdit = () => {
		setEditModal(!editModal);
	};

	const { type, vehicle } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			vehicle: state.vehicle,
		}),
		shallow
	);

	return (
		<div>
			{(vehicle?.inventoryID == null && (
				<OpenModalButton
					buttonClass="btn-sm"
					buttonColor="primary"
					onClick={toggleAdd}
					modalTitle="Select Vehicle"
					buttonText="Select"
					icon={true}
					iconClass="nc-icon nc-tap-01"
				/>
			)) || (
				<Row className="old-row justify-content-center" style={{ gap: "6px" }}>
					<OpenModalButton
						buttonClass="btn-sm btn ml-2"
						onClick={toggleEdit}
						buttonColor="primary"
						buttonText="View"
						iconClass="nc-icon nc-paper"
					/>
					{type === DealStatus.PENDING ? (
						<span>
							<OpenModalButton
								buttonClass="btn-sm"
								onClick={toggleAdd}
								buttonColor="link"
								buttonText="Select"
								iconClass="nc-icon nc-tap-01"
							/>
						</span>
					) : null}
				</Row>
			)}
			{type === DealStatus.PENDING && (
				<ChildrenModal
					modal={addModal}
					toggle={toggleAdd}
					modalTitle="Select Vehicle"
					children={<DealInventoryListData toggle={toggleAdd} />}
				/>
			)}
			<ChildrenModal
				modal={editModal}
				toggle={toggleEdit}
				modalTitle="Edit Current Vehicle"
				children={<InventoryDetailsModal isDeal={true} toggle={toggleEdit} />}
			/>
		</div>
	);
};

export default InventoryListModal;
