import React, { useMemo, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Button,
	Row,
	Table,
} from "reactstrap";
import { useTable } from "react-table";
import ChildrenModal from "components/modals/ChildrenModal";
import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteAlert,
} from "utils/alertUtils";
import { deleteCommission } from "api/DealAPI";
import useCurrentDealStore from "stores/DealStore";
import useCurrentRecapStore from "stores/RecapStore";
import shallow from "zustand/shallow";
import { personnelType, commTypeList } from "constants/Constants";
import CommissionForm from "./CommissionForm";
import { FormattedText } from "features/forms/components/InputElement";
import { showApiError } from "utils/errorRoutingUtils";

/**
 * Helper function to get commission type description
 *
 * @param {string} commType - The commission type
 * @returns {string} Commission type description
 */
const commTypeDesc = (commType) => {
	if (commType === commTypeList.SALE.value) return commTypeList.SALE.label;
	if (commType === commTypeList.FI.value) return commTypeList.FI.label;
	if (commType === commTypeList.TOTAL.value) return commTypeList.TOTAL.label;
	if (commType === commTypeList.NETPROFIT.value)
		return commTypeList.NETPROFIT.label;
	return "";
};

/**
 * CommissionTable component
 *
 * @returns {JSX.Element} The rendered component
 */
const CommissionTable = () => {
	const {
		commissions,
		editCommissions,
		salesPeople,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			commissions: state.commissions,
			editCommissions: state.editCommissions,
			salesPeople: state.salesPeople,
			calculate: state.calculate,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(commissions.length);
	const [showAddCommission, setShowAddCommission] = useState(false);
	const toggleAddCommission = () => setShowAddCommission(!showAddCommission);

	const { commGross } = useCurrentRecapStore((state) => state, shallow);

	const salespeopleList = salesPeople.map((person) => ({
		label: `${person.firstName} ${person.lastName} - ${
			personnelType.find((pt) => pt.value === person.personnelType).label
		}`,
		value: person.ID,
		dcLogin: person,
	}));

	const columns = useMemo(
		() => [
			{ Header: "#", accessor: "index", width: "200px" },
			{ Header: "Person", accessor: "person", width: "200px" },
			{ Header: "Commission Type", accessor: "commType", width: "200px" },
			{
				Header: "Commission",
				accessor: "commAmount",
				Cell: ({ value }) => (
					<FormattedText value={value} fixedDecScale={true} prefix={"$"} />
				),
				width: "200px",
			},
			{
				Header: "Bonus",
				accessor: "bonus",
				Cell: ({ value }) => (
					<FormattedText value={value} fixedDecScale={true} prefix={"$"} />
				),
				width: "200px",
			},
			{
				Header: "Other",
				accessor: "other",
				Cell: ({ value }) => (
					<FormattedText value={value} fixedDecScale={true} prefix={"$"} />
				),
				width: "200px",
			},
			{
				Header: "Total",
				accessor: "total",
				Cell: ({ value }) => (
					<FormattedText value={value} fixedDecScale={true} prefix={"$"} />
				),
				width: "200px",
			},
			{
				Header: "Actions",
				accessor: "actions",
				Cell: ({ row }) => (
					<div className="d-flex justify-content-center align-items-center">
						<Button
							className="btn btn-sm btn-primary"
							onClick={() => {
								setCurrentIndex(row.index);
								toggleAddCommission();
							}}
						>
							<i className="nc-icon nc-align-center"></i>
							<span className="noMobile"> Edit</span>
						</Button>
						<Button
							color="danger"
							className="btn-plain btn-sm ml-3"
							onClick={() => toggleModal(row.original.id, row.index, row.index)}
						>
							<i className="nc-icon nc-simple-remove" />
						</Button>
					</div>
				),
				width: "200px",
			},
		],
		//eslint-disable-next-line
		[]
	);

	const data = useMemo(
		() =>
			commissions.map((commission, index) => {
				const personLabel =
					salespeopleList.find(
						(person) => person.value === commission.personRecNum
					)?.label || "";

				return {
					index: index + 1,
					person: personLabel,
					commType: commTypeDesc(commission.commType),
					commAmount: commission.commAmount,
					bonus: commission.bonus,
					other: commission.other,
					total: commission.bonus + commission.other + commission.commAmount,
					id: commission.id,
				};
			}),
		[commissions, salespeopleList]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
	});

	const toggleModal = (currentID, currentIndex, arrayIndex) => {
		if (currentID == null) {
			const removedCommission = commissions.filter(
				(_com, index) => index !== arrayIndex
			);

			editCommissions(removedCommission);
			setCurrentIndex(removedCommission.length);
			calculate();
		} else {
			confirmDeleteAlert(removeCommission, currentID, currentIndex);
		}
	};

	const removeCommission = (ID) => {
		deleteCommission(ID).then(
			() => {
				const updatedCommissions = commissions.filter((item) => item.id !== ID);
				editCommissions(updatedCommissions);
				setCurrentIndex(updatedCommissions.length);
				calculate();
				deleteSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, deleteFailAlert);
				}
			}
		);
	};

	return (
		<>
			<ChildrenModal
				modal={showAddCommission}
				toggle={toggleAddCommission}
				modalTitle="Commission"
				children={
					<CommissionForm
						currentIndex={currentIndex}
						setCurrentIndex={setCurrentIndex}
						toggle={toggleAddCommission}
						salespersonList={salespeopleList}
					/>
				}
			/>
			<div className="px-3">
				<Button
					className="btn btn-primary btn-sm my-3"
					onClick={toggleAddCommission}
				>
					<i className="nc-icon nc-simple-add" />
					Add Commission
				</Button>
			</div>
			{rows.length > 0 && (
				<Card className="mt-1">
					<CardHeader>
						<CardTitle tag="h3" className="pb-0">
							Commissions
						</CardTitle>
					</CardHeader>
					<CardBody>
						<div style={{ overflowX: "auto", width: "100%" }}>
							<Table
								responsive
								{...getTableProps()}
								className="table table-hover table-bordered"
								style={{ minWidth: "1000px" }}
							>
								<thead>
									{headerGroups.map((headerGroup) => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map((column) => (
												<th
													{...column.getHeaderProps({
														style: { width: column.width },
													})}
												>
													{column.render("Header")}
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()}>
									{rows.map((row) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => (
													<td
														{...cell.getCellProps({
															style: { width: cell.column.width },
														})}
													>
														{cell.render("Cell")}
													</td>
												))}
											</tr>
										);
									})}
								</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			)}
			<Row className="my-3 pt-3 justify-content-center">
				<h3>Total Commissions</h3>
				<div className="w-100">
					<hr className="mb-2 w-75 mx-auto" />
				</div>
				<h3>
					<FormattedText
						value={commGross}
						fixedDecScale={true}
						prefix={"  $"}
					/>
				</h3>
			</Row>
		</>
	);
};

export default CommissionTable;
