import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentRecapStore from "stores/RecapStore";
import shallow from "zustand/shallow";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import FinancePercent from "./FinancePercent";
import FinanceCurrency from "./FinanceCurrency";

/**
 * RecapFinanceCard component to display finance details.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered RecapFinanceCard component
 */
const RecapFinanceCard = () => {
	const { apr, financeCharge } = useCurrentSaleStore(
		(state) => ({
			apr: state.apr,
			financeCharge: state.financeCharge,
		}),
		shallow
	);

	const {
		interestCost,
		dlrParticipation,
		additionalProfit,
		interestProfit,
		interestNetProfit,
		editInterestCost,
		editDlrParticipation,
		editAdditionalProfit,
	} = useCurrentRecapStore(
		(state) => ({
			interestCost: state.interestCost,
			dlrParticipation: state.dlrParticipation,
			additionalProfit: state.additionalProfit,
			interestProfit: state.interestProfit,
			interestNetProfit: state.interestNetProfit,
			editInterestCost: state.editInterestCost,
			editDlrParticipation: state.editDlrParticipation,
			editAdditionalProfit: state.editAdditionalProfit,
		}),
		shallow
	);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	return (
		<Card style={{ height: "fit-content" }}>
			<CardHeader>
				<h3>Finance (Interest)</h3>
			</CardHeader>
			<CardBody>
				<Row>
					<Col xs="12" xl="6" className="p-0">
						<FinancePercent disabled label="Sale Rate (%)" value={apr} />
						<FinancePercent
							disabled={type !== DealStatus.PENDING}
							label="Buy Rate (%)"
							value={interestCost}
							onChange={editInterestCost}
						/>
					</Col>
					<hr className="mb-2 noMobile" />
					<Col xs="12" xl="6" className="p-0">
						<FinanceCurrency
							disabled
							label="Finance Charge"
							value={financeCharge}
						/>
						<FinanceCurrency
							disabled
							label="Gross Financing Profit"
							value={interestProfit}
						/>
						<FinancePercent
							disabled={type !== DealStatus.PENDING}
							label="Participation (%)"
							value={dlrParticipation}
							onChange={editDlrParticipation}
						/>
						<FinanceCurrency
							disabled={type !== DealStatus.PENDING}
							label="Additional Financing Profit"
							value={additionalProfit}
							onChange={editAdditionalProfit}
						/>
						<FinanceCurrency
							disabled
							label="Net Financing Profit"
							value={interestNetProfit}
						/>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

RecapFinanceCard.propTypes = {
	apr: PropTypes.number.isRequired,
	financeCharge: PropTypes.number.isRequired,
	interestCost: PropTypes.number.isRequired,
	dlrParticipation: PropTypes.number.isRequired,
	additionalProfit: PropTypes.number.isRequired,
	interestProfit: PropTypes.number.isRequired,
	interestNetProfit: PropTypes.number.isRequired,
	editInterestCost: PropTypes.func.isRequired,
	editDlrParticipation: PropTypes.func.isRequired,
	editAdditionalProfit: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};

export default RecapFinanceCard;
