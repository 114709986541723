import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "reactstrap";
import RecapSummaryCard from "./RecapSummaryCard";
import RecapMainCard from "./RecapMainCard";
import RecapFinanceCard from "./RecapFinanceCard";
import useSetDeal from "features/deal/hooks/useSetDeal";
import { saveDeal } from "api/DealAPI";
import { saveFailAlert, saveSuccessAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import {
	formLoading,
	getPdf,
	createFileAndDownload,
	displayPdf,
	displayError,
} from "api/FormsAPI";
import Swal from "sweetalert2";

/**
 * Recap component to display and handle the recap of a deal.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered Recap component
 */
const Recap = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	/**
	 * Handles the print action by saving the deal and generating the PDF.
	 */
	const handlePrint = () => {
		const deal = getDeal();
		const dealID = deal.deal.ID;
		const body = {
			dealID: dealID,
			inventoryID: null,
			// 205 is ID for deal recap in dcReport
			dcReportID: 205,
			params: { dealerID: dealerID, locationID: locationID },
		};
		formLoading("PDF");
		saveDeal(deal).then(
			(res) => {
				setDeal(res.data.content);
				saveSuccessAlert();
				body.dealID = res.data.content.deal.ID;
				getPdf(body).then(
					(response) => {
						const fileName = "ST_DEAL_RECAP_" + dealID + ".pdf";
						createFileAndDownload(response, fileName);
						displayPdf(response);
						Swal.close();
					},
					(err) => {
						displayError(err);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	return (
		<Row className="justify-content-center" style={{ gap: "12px" }}>
			<Col className="col-auto p-0">
				<RecapMainCard />
			</Col>
			<Col
				xl="auto"
				md="6"
				className="p-0 d-flex flex-wrap justify-content-center"
				style={{ gap: "12px" }}
			>
				<RecapFinanceCard />
				<RecapSummaryCard />
			</Col>
			<Col className="col-12 text-center my-3">
				<Button onClick={handlePrint}>
					<i className="nc-icon nc-single-copy-04" /> Print
				</Button>
			</Col>
		</Row>
	);
};

Recap.propTypes = {
	dealerID: PropTypes.string,
	locationID: PropTypes.string,
	getDeal: PropTypes.func.isRequired,
	setDeal: PropTypes.func.isRequired,
};

export default Recap;
