import React, { useEffect, useState } from "react";
import { getSettings } from "api/SettingsAPI";
import { retrieveFailAlert } from "utils/alertUtils";
import NavBar from "components/tabs/TabsHolder";
import Loading from "components/loadingSpinner/Loading.js";
import { useHistory, useLocation } from "react-router-dom";
import useRoute from "hooks/useRoute";
import { MessageZone, PATHS } from "constants/Constants";
import useCurrentSettingStore from "stores/SettingStore";
import { useFromUser } from "stores/LocalStorageHelper";
import SignUps from "../subFeatures/settingsTabs/signUp/SignUps";
import DealTemplate from "../subFeatures/settingsTabs/dealTemplates/DealTemplate";
import ProfilePage from "../subFeatures/settingsTabs/userManagement/profile/ProfilePage";
import CompanyInfoTab from "../subFeatures/settingsTabs/userManagement/company/CompanyInfoTab";
import CompanyEmployeesPage from "../subFeatures/settingsTabs/userManagement/employees/CompanyEmployeesPage";
import useIsOwnerOrAdmin from "../subFeatures/settingsTabs/userManagement/useIsOwnerOrAdmin";
import { showApiError } from "utils/errorRoutingUtils";
import MessageTemplates from "../../communication/MessageTemplates";
import { TemplateVariableProvider } from "context/TemplateVariableContext";
import InitializeTemplateVars from "utils/initializeTemplateVars";
import InventorySettings from "../subFeatures/settingsTabs/InventoryDefault/InventorySetting";
import AccountDefaultSettings from "../../../account/AccountDefaultSetting";
import DealDefaultSettings from "../subFeatures/settingsTabs/dealDefault/DealDefaultSettings";

const SettingsPage = () => {
	const history = useHistory();
	const location = useLocation();
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const newRoute = useRoute(PATHS.DASHBOARD);
	//const {fn} = useCurrentLoginStore((state) => state, shallow);
	//	const bhph = useFromLocations("fn");

	let activeTab = 0;

	if (location?.state?.tab === "signUp") {
		activeTab = 4;
	} else if (location?.state?.tab === "dealerBond") {
		activeTab = 1;
	}

	const isOwnerOrAdmin = useIsOwnerOrAdmin();
	const { setSettingFields } = useCurrentSettingStore();

	const [isLoading, setIsLoading] = useState(true);
	const [showProfile, setShowProfile] = useState(false);

	const getData = () => {
		getSettings(dealerID, locationID).then(
			(res) => {
				console.log(res);
				//set res to store.
				setSettingFields(res.data.content);
				setIsLoading(false);
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					history.push(newRoute);
				}
			}
		);
	};

	useEffect(
		() => {
			if (isOwnerOrAdmin) {
				getData();
				setShowProfile(false);
			} else {
				setIsLoading(false);
				setShowProfile(true);
			}
		},
		// eslint-disable-next-line
		[isOwnerOrAdmin]
	);

	const tabs = [
		"Profile",
		"Dealership Info",
		"Employees",
		"Deal Default",
		"Sign Ups",
		"Deal Template",
		"Message Templates",
		"Inventory Default",
		[4757, 6730, 7942, 5456, 7906, 7464, 7664].includes(locationID)
			? "BHPH Default"
			: "",
	];

	const tabComponents = [
		<ProfilePage />,
		<CompanyInfoTab />,
		<CompanyEmployeesPage />,
		<DealDefaultSettings />,
		<SignUps />,
		<DealTemplate />,
		<MessageTemplates />,
		<InventorySettings />,
		[4757, 6730, 7942, 5456, 7906, 7464, 7664].includes(locationID) ? (
			<div className="pt-8 col-md-12 d-flex justify-content-center">
				<AccountDefaultSettings />,
			</div>
		) : null,
	];

	return (
		<TemplateVariableProvider>
			<InitializeTemplateVars messageZone={MessageZone.DEAL}>
				<div className="mx-0 px-3 justify-content-center content">
					{isLoading && <Loading />}
					{showProfile && (
						<div className="content">
							<ProfilePage />
						</div>
					)}
					{!showProfile && !isLoading && (
						<NavBar
							navClass="nav-pills-primary justify-content-center nav nav-pills"
							active={activeTab}
							tabs={tabs}
							tabComponents={tabComponents}
						/>
					)}
				</div>
			</InitializeTemplateVars>
		</TemplateVariableProvider>
	);
};

export default SettingsPage;
