import React from "react";
import { Row, Col, Form } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import { PayOffMethod } from "../constants/Constants";
import InputElement, {
	CurrencyInput,
	IntegerInput,
	SelectorElement,
} from "features/forms/components/InputElement";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider } from "react-hook-form";
import InputWrapper from "features/forms/components/InputWrapper";

const AccountPayoff = () => {
	const { account } = useCurrentAccountStore((state) => state, shallow);
	console.log(account);
	// Define rules for each input field
	const schema = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<Row>
						<Col>
							<InputWrapper
								formTitle="PayOff "
								inputComponents={[
									<SelectorElement
										{...methods}
										readOnly={true}
										name="payOffMethod"
										label="PayOff Method"
										value={account.payoffMethod}
										// onChange={(e) => editPayOffMethod(e.target.value)}
										options={PayOffMethod}
										colSize="col-lg-12"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.amtFinanced - account.principle} //account.amtFinanced - account.principle
										// onChange={setPrincipleRemaining}
										name="principle"
										label="Remain Principle"
										type="number"
										colSize="col-xl-12"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.unPaidInterest} //account.unPaidInterest
										// onChange={setInterestDue}
										name="interest"
										label="Interest Accrued"
										type="number"
										colSize="col-xl-12"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.lateFeeDue} //account.unPaidInterest
										// onChange={setLateFee}
										name="lateFeeDue"
										label="Remain LateFee"
										type="number"
										colSize="col-xl-12"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.debitCreditTotal - account.debitPaid} //account.unPaidInterest
										// onChange={setRemainAdj}
										name="remainAdj"
										label="Remain Adj"
										type="number"
										colSize="col-xl-12"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.pickupTotal - account.pickupPaid} //account.unPaidInterest
										// onChange={setRemainpickup}
										name="remainpickup"
										label="Remain pickup"
										type="number"
										colSize="col-xl-12"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.payoff}
										// onChange={setPayoff}
										name="payoff"
										label="Total Payoff"
										type="number"
										colSize="col-lg-12"
										labelClass="bigText"
										style={{
											backgroundColor: "green", // Change the background color
											color: "white", // Change the font color
											fontSize: "20px",
										}}
									/>,
								]}
							/>
						</Col>
						<Col>
							<InputWrapper
								formTitle="Past Due"
								inputComponents={[
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.principleDue} //account.amtFinanced - account.principle
										// onChange={setPrincipleRemaining}
										name="principle"
										label="Principle Due"
										type="number"
										colSize="col-xl-12"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.interestDue}
										// onChange={setInterestDue}
										name="interest"
										label="Interest Due"
										type="number"
										colSize="col-xl-12"
									/>,

									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.lateFeeDue} //account.unPaidInterest
										// onChange={setLateFee}
										name="lateFeeDue"
										label="LateFee Due"
										type="number"
										colSize="col-xl-12"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.debitCreditTotal - account.debitPaid} //account.unPaidInterest
										// onChange={setRemainAdj}
										name="remainAdj"
										label="Adj Due"
										type="number"
										colSize="col-xl-12"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.pickupPastDue} //account.unPaidInterest
										// onChange={setRemainpickup}
										name="remainpickup"
										label="pickup Due"
										type="number"
										colSize="col-xl-12"
									/>,

									<CurrencyInput
										disableValidation
										wholerow
										colSize="col-xl-12"
										readOnly={true}
										value={account.pastDueAmt}
										// onChange={setPastDueAmt}
										name="pastDueAmt"
										label="Past Due Amt"
										type="number"
										labelClass="bigText"
										style={{
											backgroundColor: "red", // Change the background color
											color: "white", // Change the font color
											fontSize: "20px",
										}}
									/>,
								]}
							/>
						</Col>
						<Col>
							<InputWrapper
								formTitle="Loan Dates "
								inputComponents={[
									<InputElement
										{...methods}
										readOnly={true}
										value={account.dateOfLastPmt}
										name="date"
										label="Last Paid Date"
										type="date"
										colSize="col-xl-12"
									/>,
									<InputElement
										{...methods}
										readOnly={true}
										value={account.dateOfLastFullPmt}
										name="date"
										label="Last Full Paid Date"
										type="date"
										colSize="col-xl-12"
									/>,
									<InputElement
										{...methods}
										readOnly={true}
										value={account.dateOfLastFullPmt}
										name="date"
										label="Paid Through Date"
										type="date"
										colSize="col-xl-12"
									/>,
									<InputElement
										{...methods}
										readOnly={true}
										value={account.lastScheduledPmtDate}
										name="date"
										label="Previos Scheduled Date"
										type="date"
										colSize="col-xl-12"
									/>,
									<InputElement
										{...methods}
										readOnly={true}
										value={account.nextDueDate}
										name="date"
										label="Next Scheduled Date"
										type="date"
										colSize="col-xl-12"
									/>,
									<IntegerInput
										{...methods}
										disableValidation
										readOnly={true}
										value={account.daysLate}
										name="date"
										label="Days Late"
										type="number"
										colSize="col-xl-12"
										labelClass="bigText"
										style={{
											backgroundColor: "red", // Change the background color
											color: "white", // Change the font color
											fontSize: "20px",
										}}
									/>,
								]}
							/>
						</Col>

						<Col>
							<InputWrapper
								formTitle="Pickup Dates "
								inputComponents={[
									<InputElement
										{...methods}
										readOnly={true}
										value={account.pickupLastPmtDate}
										name="date"
										label="Last Paid Date"
										type="date"
										colSize="col-xl-12"
									/>,
									<InputElement
										{...methods}
										readOnly={true}
										value={account.paidThroughDate}
										name="date"
										label="Paid Through Date"
										type="date"
										colSize="col-xl-12"
									/>,
									<InputElement
										{...methods}
										readOnly={true}
										value={account.pickupLastScheduledPmtDate}
										name="date"
										label="Previos Scheduled Date"
										type="date"
										colSize="col-xl-12"
									/>,
									<InputElement
										{...methods}
										readOnly={true}
										value={account.pickupNextDueDate}
										name="date"
										label="Next Scheduled Date"
										type="date"
										colSize="col-xl-12"
									/>,
									<IntegerInput
										{...methods}
										disableValidation
										readOnly={true}
										value={account.pickupDaysLate}
										name="date"
										label="Picup Days Late"
										type="number"
										colSize="col-xl-12"
										labelClass="bigText"
										style={{
											backgroundColor: "red", // Change the background color
											color: "white", // Change the font color
											fontSize: "20px",
										}}
									/>,
								]}
							/>
						</Col>
						<Col></Col>
						<Col></Col>
					</Row>
				</Form>
			</FormProvider>
		</>
	);
};

export default AccountPayoff;
