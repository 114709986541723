import React from "react";
import { Button } from "reactstrap";

import { ADD, TIME_FORMAT } from "./NoteConstants";
import dayjs from "dayjs";
import { useFromUser } from "../../stores/LocalStorageHelper";

/**
 * Render the Add/Save and Clear buttons.
 *
 * @param {number} index - Local state for the length of the notes array
 * @param {String} lNote - Local state for description
 * @param {String} name - Local state for name
 * @param {Array} notes - Store Notes array containing objects with
 * information about each note.
 * @param {String} buttonText - Local state text for the Add/Save button
 * @param {React.SetStateAction} editNotes - Setter function for the
 * Store notes field.
 * @param {React.SetStateAction} setButtonText - Local button text
 * state setter function.
 * @param {Function} saveNotes - Calls save api to save the object with
 * the updated notes.
 * @callback clearFields - Function to set local states to initial value
 */
const NoteModalButton = ({
	index,
	lNote,
	notes,
	buttonText,
	editNotes,
	setButtonText,
	clearFields,
	disableButtons,
	saveNotes,
	toggle,
}) => {
	const today = dayjs().format(TIME_FORMAT);
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");

	const name = firstName + " " + lastName;
	// Handles the add/save button click. Creates the notes object to add to
	// the notes array with the user information.
	const handleAddOrSave = () => {
		if (!lNote || lNote === " ") {
			return;
		}

		let newNotes = notes;
		let createdOn, createdBy, modifiedBy;

		createdOn = notes[index]?.created || today;
		createdBy = notes[index]?.createdBy || name;
		modifiedBy = name;

		const entry = {
			createdOn,
			createdBy,
			modifiedOn: today,
			modifiedBy,
			description: lNote,
		};

		newNotes[index] = entry;
		setButtonText(ADD);
		editNotes(newNotes);
		clearFields();
		saveNotes();
		toggle();
	};

	return (
		<div className="d-flex justify-content-center mt-2">
			<Button
				className="btn-primary btn-md"
				readOnly={disableButtons}
				color="primary"
				onClick={handleAddOrSave}
			>
				<i className="nc-icon nc-simple-add"></i> Add
			</Button>
		</div>
	);
};

export default NoteModalButton;
