import history from "utils/createBrowserHistory";

export const useFromUser = (value) => {
	try {
		const user = sessionStorage.getItem("user");
		const item = JSON.parse(user)[value];
		if (item === null) {
			history.push("/login");
		}
		return item;
	} catch {
		history.push("/");
	}
};

export const useDealerInfo = () => {
	try {
		const user = sessionStorage.getItem("user");
		const dealerID = JSON.parse(user).dealerID;
		const locationID = JSON.parse(user).locationID;
		if (dealerID === null || locationID === null) {
			history.push("/login");
		}
		return { dealerID, locationID };
	} catch {
		history.push("/");
	}
};

export const useFromLocations = (value) => {
	try {
		const user = sessionStorage.getItem("user");
		const item = JSON.parse(user).locations[value];
		if (item === null) {
			history.push("/login");
		}
		return item;
	} catch {
		history.push("/");
	}
};

export const useUser = () => {
	try {
		const user = sessionStorage.getItem("user");
		const item = JSON.parse(user);
		if (item === null) {
			history.push("/login");
		}
		return item;
	} catch {
		history.push("/");
	}
};

export const useFromUserNoRedirect = (value) => {
	try {
		const user = sessionStorage.getItem("user");
		const item = JSON.parse(user)[value];
		if (item === null) {
			return "";
		}
		return item;
	} catch {
		console.error("Missing user.");
	}
};

export const usePartnerSend = () => {
	try {
		const user = sessionStorage.getItem("dealerPartnerSend");
		const item = JSON.parse(user);
		if (item === null) {
			return "";
		}
		return item;
	} catch {
		console.error("Missing partner send.");
	}
};

export const useFromPartnerSend = (value) => {
	try {
		const user = sessionStorage.getItem("dealerPartnerSend");
		const item = JSON.parse(user)[value];
		if (item === null) {
			return "";
		}
		return item;
	} catch {
		console.error("Missing partner send.");
	}
};

export const usePartnerLink = () => {
	try {
		const user = sessionStorage.getItem("dealerPartnerLink");
		const item = JSON.parse(user);
		if (item === null) {
			return "";
		}
		return item;
	} catch {
		console.error("Missing partner link.");
	}
};

export const useFromPartnerLink = (value) => {
	try {
		const user = sessionStorage.getItem("dealerPartnerLink");
		const item = JSON.parse(user)[value];
		if (item === null) {
			return "";
		}
		return item;
	} catch {
		console.error("Missing partner link.");
	}
};

export const useFromFormsReports = (value) => {
	try {
		const user = sessionStorage.getItem("formsReports");
		const item = JSON.parse(user)[value];
		if (item === null) {
			return [];
		}
		return item;
	} catch {
		console.error("Missing forms and reports");
	}
};

export const useFormsReports = () => {
	try {
		const user = sessionStorage.getItem("formsReports");
		const item = JSON.parse(user);
		if (item === null) {
			return {};
		}
		return item;
	} catch {
		console.error("Missing forms and reports");
	}
};
