import React, { useEffect } from "react";

import { showApiError } from "../utils/errorRoutingUtils";
import { useHistory } from "react-router-dom";

import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import useAccountDefaultStore from "./AccountDefaultsStore";
import { getAccountDetails, getAcctDefaults } from "./AccountAPI";
import useSetAccount from "./useSetAccount";
import AccountTabs from "./AccountTabs";
import { PATHS } from "../constants/Constants";
import { retrieveFailAlert } from "../utils/alertUtils";
import useRoute from "../hooks/useRoute";
import { useFromUser } from "../stores/LocalStorageHelper";
import AccountActionBar from "./AccountActionBar";
import Loading from "../components/loadingSpinner/Loading";
const AccountDetailPage = (prop) => {
	const { editAcctDefaults } = useAccountDefaultStore(
		(state) => state,
		shallow
	);
	const acctID = prop.location.state?.acctID;
	const acctListPage = useRoute(PATHS.ACCT_LIST);
	const acctAddPage = useRoute(PATHS.ACCT_ADD);
	const history = useHistory();
	console.log(history);
	//	const acctID = history.location.state?.ID;
	const locationID = useFromUser("locationID");
	// const firstName = useFromUser("firstName");
	// const lastName = useFromUser("lastName");
	const setAccount = useSetAccount();

	// Account store
	const {
		isLoading,
		resetAcct,
		editIsLoading,
		// editAccount,
	} = useCurrentAccountStore(
		(state) => ({
			//editAccount: state.editAccount,
			isLoading: state.isLoading,
			resetAcct: state.reset,
			editIsLoading: state.editIsLoading,
		}),
		shallow
	);

	const getData = () => {
		console.log(acctID);
		if (acctID != null) {
			Promise.all([
				getAcctDefaults(locationID),
				getAccountDetails(acctID),
			]).then(
				(res) => {
					console.log(res);
					console.log(res[0].data.content);
					editAcctDefaults(res[0].data.content);

					setAccount(res[1]);

					//					updateSettings(defaultSettings, "");
					//					editModifiedBy(lastName + ", " + firstName);
					// editAccount({
					// 	//...res[1].data.content.account,
					// 	modifiedBy: lastName + ", " + firstName,
					// });
					//	history.push(acctDetails, { ID: acctID });
					console.log(history);
					editIsLoading(false);
				},
				(err) => {
					console.log(err);
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(acctListPage);
					}
				}
			);
		} else {
			history.push(acctAddPage);
		}
	};
	// console.log(deal);
	useEffect(() => {
		console.log(isLoading);
		if (isLoading) {
			console.log("loading Account");
			getData();
		}
		return () => {
			resetAcct();
		};

		// eslint-disable-next-line
	}, []);

	const TabHandler = () => {
		// 	if (type !== DealStatus.PENDING) {
		// 		return <SoldOrDeletedDetailTab />;
		// 	} else {
		// 		return <PendingDetailTab />;
		// 	}
		return <AccountTabs />;
	};

	return (
		<div>
			<div className="col-md-12 px-0 content">
				{(isLoading && <Loading />) || (
					<>
						<AccountActionBar />
						<TabHandler />
					</>
				)}
			</div>
		</div>
	);
};

// const SoldOrDeletedDetailTab = () => {
// 	useCalculateGlobalfield();
// 	return <DealTabs />;
// };

// const PendingDetailTab = () => {
// 	useCalculateSales();
// 	useUpdateCountyRecNum();
// 	return <DealTabs />;
// };

export default AccountDetailPage;
