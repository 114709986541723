import React from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import dayjs from "dayjs";
import shallow from "zustand/shallow";

import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteAlert,
} from "utils/alertUtils";
import { deleteReconditioning } from "api/InventoryAPI";
import useCurrentVehicleStore from "stores/InventoryStore";
import { vehicleCostType, DealStatus } from "constants/Constants.js";
import { FormattedText } from "../../../../forms/components/InputElement";
import { getVendorByID } from "api/VendorsAPI";
import { showApiError } from "utils/errorRoutingUtils";
import useSaveInventory from "../../../hooks/useSaveInventory";

const InventoryCostsTable = ({ setCurrentIndex, toggle }) => {
	// Vehicle store
	const {
		status,
		editVendorName,
		costHist,
		editCostHist,
		allVendorList,
	} = useCurrentVehicleStore(
		(state) => ({
			status: state.status,
			editVendorName: state.editVendorName,
			costHist: state.costHist,
			editCostHist: state.editCostHist,
			allVendorList: state.allVendorList,
		}),
		shallow
	);

	const saveInventory = useSaveInventory();

	console.log(costHist);
	console.log(allVendorList);

	const costHead = [
		"#",
		"Cost Type",
		"Vendor",
		"Description",
		"Department",
		"Date",
		"Cost",
		"Work Completed",
		"Actions",
	];

	let total = 0;
	const getTotals = (data) => {
		total = 0;
		data.forEach((item) => {
			total += parseFloat(item.cost);
		});
		return total;
	};

	// Modal
	const toggleModal = (currentID) => {
		confirmDeleteAlert(removeCost, currentID);
	};
	const removeCost = (ID) => {
		deleteReconditioning(ID).then(
			() => {
				let obj = costHist.filter((item) => item.ID !== ID);
				editCostHist(obj);
				setCurrentIndex(obj.length);
				deleteSuccessAlert();
				saveInventory();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, deleteFailAlert);
				}
			}
		);
	};

	const head = costHead.map((key, index) => (
		<th key={`${key} ${index}`}>{key}</th>
	));

	const VendorHandler = ({ obj, index }) => {
		let vendorName = "";
		console.log(obj);
		if (Number(obj.costType) === 2) {
			vendorName = allVendorList.flooringVendor.find(
				(vendor) => vendor.ID === obj.vendorRecNum
			)?.name;
		} else {
			vendorName = allVendorList.reconSupplier.find(
				(vendor) => vendor.ID === obj.vendorRecNum
			)?.name;
		}
		return <div key={index}>{vendorName}</div>;
	};
	const rows = costHist.map((key, index) => (
		<tr key={`${key} ${index}`}>
			<th scope="row">{index + 1}</th>
			<td>
				{
					vehicleCostType.find((row) => row.value === parseInt(key.costType))
						?.label
				}
			</td>
			<td>
				<VendorHandler obj={key} index={index} />
			</td>
			<td>{key.description}</td>
			<td>{key.department}</td>
			<td>
				{key.date != null || key.date.trim() !== ""
					? dayjs(key.date).format("MM/DD/YYYY")
					: ""}
			</td>
			<td>
				<FormattedText value={key.cost} fixedDecScale={true} prefix={"$"} />
			</td>
			<td>
				{key.dateCompleted != null && key.dateCompleted !== ""
					? dayjs(key.dateCompleted).format("MM/DD/YYYY")
					: ""}
			</td>
			<td>
				<div className="d-flex justify-content-center">
					<button
						// href="#editCost"
						className="btn btn-md btn-primary"
						readOnly={
							status === DealStatus.SOLD || status === DealStatus.DELETED
						}
						onClick={() => {
							getVendorByID(key.vendorRecNum).then(
								(res) => {
									editVendorName(res.data.content.name);
									setCurrentIndex(index);
									toggle();
								},
								(error) => {
									console.log(error);
								}
							);

							console.log("ID for address at index " + index + ": " + key.ID);
						}}
					>
						<i className="nc-icon nc-align-center" />
						<span className="noMobile"> Edit</span>
					</button>
					<button
						// href="#deleteCost"
						className="btn btn-md btn-link-danger ml-2"
						readOnly={
							status === DealStatus.SOLD || status === DealStatus.DELETED
						}
						onClick={() => {
							console.log(key.ID + " " + index);
							toggleModal(key.ID);
						}}
					>
						<i className="nc-icon nc-simple-remove" /> Remove
					</button>
				</div>
			</td>
		</tr>
	));

	return (
		<>
			<Card className="mt-2">
				<CardBody>
					<Row className="mx-0 px-3">
						<Col>
							<Table striped bordered hover responsive>
								<thead>
									<tr>{head}</tr>
								</thead>
								<tbody>{rows}</tbody>
							</Table>
						</Col>
					</Row>
				</CardBody>
			</Card>
			<Row>
				<Col className="mt-3 col-6 text-right">
					<h6>Total Cost:</h6>
				</Col>
				<Col className="mt-3 col-6 text-left">
					<h6>${getTotals(costHist)}</h6>
				</Col>
			</Row>
		</>
	);
};
export default InventoryCostsTable;
