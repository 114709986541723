import { userBaseUrl } from "../api/Constants";
//import { LOCAL } from "../api/Constants";
import createAxiosInstance from "../utils/apiUtils";
//console.log(LOCAL);
console.log(userBaseUrl);
const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/bhph/",
	//baseURL: LOCAL,
});
// const localInstance = getAxiosInstance({
// 	baseURL: LOCAL,
// });
//Get a list of all the Accounts for a by a AccounterID and locationID
export const getAccountList = async (accounterID, locationID) => {
	return await instance.get(`list/${accounterID}/${locationID}`);
};

// Get number of the Accounts by Status
export const getAccountStatusList = async (locationID) => {
	return await instance.get(`numAcctByStatus/${locationID}`);
};

// Get a list of the Accounts by Status
export const getAccountListByStatus = async (dealerID, locationID, status) => {
	return await instance.get(`list/${dealerID}/${locationID}/${status}`);
};

// Get a list of customers needed for Account header
export const getAccountCustomerList = async (dealerID, locationID) => {
	return await instance.get(`header/customer/list/${dealerID}/${locationID}`);
};

// Saves a Account
export const saveAccount = async (body) => {
	console.log(body);

	return await instance.post(`save`, body);
};

// Saves a saveAccountTable
export const saveAccountTable = async (body) => {
	console.log(body);

	return await instance.post(`saveAccountTable`, body);
};

// Delete a Account
export const deleteAccount = async (accountID, deleteType) => {
	return await instance.get(`delete/${accountID}/${deleteType}`);
};

// Retrieves information about a Account
export const getAccountDetails = async (accountID) => {
	return await instance.get(`${accountID}`);
};
// Retrieves  Account Defaults
export const getAcctDefaults = async (locationID) => {
	return await instance.get(`default/${locationID}`);
};

// Save  Account Defaults
export const saveAcctDefaults = async (body) => {
	console.log(body);

	return await instance.post(`saveAccountDefaults`, body);
};

// Transfer Deal to BHPH
export const transferDealToBHPH = async (dealID) => {
	return await instance.get(`transferToBHPH/${dealID}`);
};
export const deleteAdjustment = async (adjID) => {
	return await instance.get(`deleteAdj/${adjID}`);
};
export const deleteLateFee = async (lateID) => {
	return await instance.get(`deleteLateFee/${lateID}`);
};
