import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";

import useCurrentCustomerStore from "stores/CustomerStore";
import Customer700CreditTab from "../contactTabs/credit700Tab/Customer700CreditTab";
import ChildrenModal from "components/modals/ChildrenModal";
import FileUploader from "components/fileUploader/FileUploader";

import Credit from "assets/img/700CreditLogo2.png";
import Experian from "assets/img/experianLogo.png";
import Equifax from "assets/img/equifaxLogo.png";
import Transunion from "assets/img/transUnionLogo.png";

import { saveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { useFromPartnerLink, useFromUser } from "stores/LocalStorageHelper";
import { getCustomerPhoto, uploadCustomerPhoto } from "api/AttachmentsAPI";

import shallow from "zustand/shallow";

const CustomerDetailHeader = () => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const isCreditSignup = useFromPartnerLink("isCreditSignup");

	const [tuModal, setTuModal] = useState(false);
	const [xpnModal, setXpnModal] = useState(false);
	const [efxModal, setEfxModal] = useState(false);
	const [profilePicture, setProfilePicture] = useState({});
	const [profilePictureSrc, setProfilePictureSrc] = useState(null);

	const tuToggle = () => {
		setTuModal(!tuModal);
	};
	const xpnToggle = () => {
		setXpnModal(!xpnModal);
	};
	const efxToggle = () => {
		setEfxModal(!efxModal);
	};

	// Customer store
	const {
		customerID,
		creditScoreXpn,
		creditScore,
		creditScoreEfx,
	} = useCurrentCustomerStore(
		(state) => ({
			customerID: state.ID,
			firstName: state.firstName,
			lastName: state.lastName,
			creditScoreXpn: state.creditScoreXpn,
			creditScore: state.creditScore,
			creditScoreEfx: state.creditScoreEfx,
		}),
		shallow
	);

	// Converts the bytes of a file
	const setCustomerPhotoSrc = (byteArray) => {
		if (byteArray.byteLength > 0) {
			const blob2File = new Blob([byteArray]);
			const blobUrl = URL.createObjectURL(blob2File);

			setProfilePictureSrc(blobUrl);
		}
	};

	useEffect(() => {
		customerID != null &&
			getCustomerPhoto(dealerID, locationID, customerID).then(
				(res) => setCustomerPhotoSrc(res.data),
				(err) => console.log(err)
			);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (customerID != null && Object.keys(profilePicture).length > 0) {
			const formData = new FormData();

			Object.keys(profilePicture).forEach((key) =>
				formData.append("file", profilePicture[key])
			);

			uploadCustomerPhoto(dealerID, locationID, customerID, formData).then(
				(res) => {
					setProfilePicture({});
					setCustomerPhotoSrc(res.data);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
		// eslint-disable-next-line
	}, [profilePicture]);

	return (
		<>
			<div className="d-flex my-3">
				<Card className="detail-header-card card-plain d-flex">
					{profilePictureSrc != null && (
						<img
							className="pb-3 px-3"
							src={profilePictureSrc}
							alt="customer-profile"
						/>
					)}
					<FileUploader
						maxFileSize={20}
						iconClass="fa fa-camera"
						hideIconText
						setUploads={setProfilePicture}
						accept=".png, .jpeg, .jpg ,.webp"
						acceptArray={[".png", ".jpeg", ".jpg", ",.webp"]}
						single
					/>
				</Card>

				<div className="col-3 detail-header-card">
					<Card className="zoom">
						<CardBody onClick={xpnToggle} className="text-center">
							<img src={Experian} alt="experian" className="rounded" />
							Score: {creditScoreXpn ? creditScoreXpn : "None"}
						</CardBody>
					</Card>
				</div>
				<div className="col-3 detail-header-card">
					<Card className="zoom">
						<CardBody onClick={tuToggle} className="text-center">
							<img src={Transunion} alt="transunion" className="rounded" />
							Score: {creditScore ? creditScore : "None"}
						</CardBody>
					</Card>
				</div>
				<div className="col-3 detail-header-card">
					<Card className="zoom">
						<CardBody onClick={efxToggle} className="text-center">
							<img src={Equifax} alt="equifax" className="rounded" />
							Score: {creditScoreEfx ? creditScoreEfx : "None"}
						</CardBody>
					</Card>
				</div>

				{!isCreditSignup && (
					<div className="col-xl-2">
						<Card className="zoom">
							<a
								href="https://700credit.com/dealerclick"
								target="_blank"
								rel="noopener noreferrer"
								className=""
							>
								<CardBody>
									<img src={Credit} alt="700credit" className="rounded" />
									<div className="p text-center">Sign Up</div>
								</CardBody>
							</a>
						</Card>
					</div>
				)}
			</div>
			{/*modals for credit scores*/}
			<>
				<ChildrenModal
					modal={tuModal}
					toggle={tuToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={<Customer700CreditTab bureau="TU" id={customerID} />}
				/>
				<ChildrenModal
					modal={xpnModal}
					toggle={xpnToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={<Customer700CreditTab bureau="XPN" id={customerID} />}
				/>
				<ChildrenModal
					modal={efxModal}
					toggle={efxToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={<Customer700CreditTab bureau="EFX" id={customerID} />}
				/>
			</>
		</>
	);
};
export default CustomerDetailHeader;
