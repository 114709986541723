import React, { useState, useEffect } from "react";
import { CardBody, Row, Col, Card, CardTitle } from "reactstrap";

import InputWrapper from "features/forms/components/InputWrapper";
import {
	OnlineCreditAppStatus,
	AdSource,
} from "../../../../constants/Constants";
import InputElement, {
	WorkPhoneInputElement,
	PhoneInputElement,
	SelectorElement,
} from "../../../forms/components/InputElement";
import { getVehicleDetails } from "../../../../api/InventoryAPI";
import DeleteCreditAppButton from "../../helperFunctions/DeleteCreditAppButton.js";
import { CurrencyInput } from "../../../forms/components/InputElement.js";
import Experian from "assets/img/experianLogo.png";
import Equifax from "assets/img/equifaxLogo.png";
import Transunion from "assets/img/transUnionLogo.png";
import Credit from "assets/img/700CreditLogo2.png";
import ChildrenModal from "../../../../components/modals/ChildrenModal";
import { updateCreditAppStatus } from "../../../../api/CreditAppApi.js";
import Credit700PreScreen from "../../components/Credit700PreScreen";
import { useFromPartnerLink } from "../../../../stores/LocalStorageHelper";
import OFAC from "../../helperFunctions/OFAC";

const OnlineCreditAppDetailsPage = ({ creditApp, toggle }) => {
	// creditApp store
	console.log(creditApp);

	const [creditAppVehicle, setCreditAppVehicle] = useState({});
	const [status, setStatus] = useState(creditApp?.active);

	const dev = process.env.NODE_ENV === "development";

	console.log(creditApp);
	console.log(creditAppVehicle);

	const [tuModal, setTuModal] = useState(false);
	const [xpnModal, setXpnModal] = useState(false);
	const [efxModal, setEfxModal] = useState(false);

	const isCreditSignup = useFromPartnerLink("isCreditSignup");

	const tuToggle = () => {
		setTuModal(!tuModal);
	};
	const xpnToggle = () => {
		setXpnModal(!xpnModal);
	};
	const efxToggle = () => {
		setEfxModal(!efxModal);
	};

	// private String suffix;
	// private String homeAddress;
	// private String homeCity;
	// private String homeState;
	// private String homeZipCode;
	// private String HomeUnit;
	// private String homeCountry;
	// private String DL;
	// private String DLState;
	// private LocalDate dlExpires;
	// private String dob;
	// private String SSN;

	const buyer = [
		<InputElement
			disableValidation={true}
			value={creditApp.companyName}
			readOnly={true}
			//onChange={(e) => editFirstName(e.target.value)}
			name="companyName"
			label="Company Name"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={creditApp.firstName}
			readOnly={true}
			//onChange={(e) => editFirstName(e.target.value)}
			name="firstName"
			label="First Name"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={creditApp.middleName}
			readOnly={true}
			//onChange={(e) => editFirstName(e.target.value)}
			name="middleName"
			label="Middle Name"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.lastName}
			//onChange={(e) => editLastName(e.target.value)}
			name="lastName"
			label="Last Name"
			type="text"
		/>,
		<InputElement
			readOnly={true}
			disableValidation={true}
			value={creditApp.dob}
			name="dob"
			label="Date of Birth"
			type="text"
			//onChange={(e) => handleChange("driverLicExpDate", e.target.value)}
		/>,

		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.contactEmail}
			name="email"
			label="Email"
			type="text"
		/>,
		<PhoneInputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.mobilePhone}
			//			onChange={editCellPhone}
			name="cellPhone"
			label="Cell Phone"
		/>,
		<PhoneInputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.homePhone}
			name="homePhone"
			label="Primary Phone"
		/>,

		<WorkPhoneInputElement
			disableValidation={true}
			readOnly={true}
			value={
				creditApp.workPhone +
				(creditApp.workPhoneExt === null ? "" : creditApp.workPhoneExt)
			}
			name="workPhone"
			label="Work Phone"
		/>,
		<PhoneInputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.fax}
			name="fax"
			label="Fax"
		/>,
		<InputElement
			readOnly={true}
			disableValidation={true}
			value={creditApp.dl}
			name="driverLicense"
			label="Driver License"
			type="text"
			//onChange={(e) => handleChange("driverLicense", e.target.value)}
		/>,
		<InputElement
			readOnly={true}
			disableValidation={true}
			value={creditApp.dlstate}
			name="DLState"
			label="DL State"
			type="text"
			//onChange={(e) => handleChange("driverLicense", e.target.value)}
		/>,

		<InputElement
			readOnly={true}
			disableValidation={true}
			value={creditApp.dlExpires}
			name="driverLicExpDate"
			label="DL ExpDate"
			type="date"
			//onChange={(e) => handleChange("driverLicExpDate", e.target.value)}
		/>,
		<OFAC />,
		<h5 className="col-12 mb-3">Address</h5>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={
				creditApp.homeAddress +
				(creditApp.homeUnit === null ? "" : " - " + creditApp.homeUnit)
			}
			name="Street"
			label="Street"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.homeCity}
			name="currentCity"
			label="City"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.homeState}
			name="state"
			label="State"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.homeZipCode}
			name="zip"
			label="Zip"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.timeAtAddress}
			name="timeAtAddress"
			label="Start Date"
		/>,
		<CurrencyInput
			disableValidation={true}
			readOnly={true}
			value={creditApp.rent}
			name="rent"
			label="rent/morgage"
		/>,
		/////////
		<h5 className="col-12 mb-3">Work Address</h5>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.workAddress}
			name="workAddress"
			label="Street"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.workCity}
			name="workCity"
			label="City"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.workState}
			name="workState"
			label="State"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.workZipCode}
			name="workZipCode"
			label="Zip"
		/>,

		<h5 className="col-12 mb-3">Income</h5>,
		<CurrencyInput
			disableValidation={true}
			readOnly={true}
			value={creditApp.income}
			name="income"
			label="Income"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.incomeType}
			name="incomeType"
			label="Income Type"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.otherIncome}
			name="otherIncome"
			label="Other Income"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.monthlyBonuses}
			name="monthlyBonuses"
			label="Monthly Bonuses"
		/>,

		<h5 className="col-12 mb-3">Employment</h5>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.workCompanyName}
			name="workCompanyName"
			label="Work Company Name"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.employmentStart}
			name="employmentStart"
			label="Emp Start Date"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.employmentEnd}
			name="employmentEnd"
			label="Emp End Date"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.occupation}
			name="occupation"
			label="Occupation"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.employerAddress}
			name="employerAddress"
			label="Employer Address"
		/>,
		<PhoneInputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.employerPhone}
			name="employerPhone"
			label="Employer Phone"
		/>,

		<CurrencyInput
			disableValidation={true}
			readOnly={true}
			value={creditApp.salary}
			name="salary"
			label="Salary"
		/>,
	];
	const cobuyer = [
		<InputElement
			disableValidation={true}
			value={creditApp.coBuyerCompanyName}
			readOnly={true}
			//onChange={(e) => editFirstName(e.target.value)}
			name="coBuyerCompanyName"
			label="CoBuyer Company Name"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={creditApp.coBuyerFirstName}
			readOnly={true}
			name="coBuyerFirstName+"
			label="First Name"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={creditApp.coBuyerMiddleName}
			readOnly={true}
			name="coBuyerMiddleName"
			label="Middle Name"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerLastName}
			name="coBuyerLastName"
			label="Last Name"
			type="text"
		/>,

		<InputElement
			readOnly={true}
			disableValidation={true}
			value={creditApp.coBuyerDOB}
			name="coBuyerDOB"
			label="Date of Birth"
			type="text"
			//onChange={(e) => handleChange("driverLicExpDate", e.target.value)}
		/>,

		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerEmail}
			name="coBuyerEmail"
			label="Email"
			type="text"
		/>,
		<PhoneInputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerCell}
			name="coBuyerCell"
			label="Cell Phone"
		/>,
		<PhoneInputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerHomePhone}
			name="coBuyerHomePhone"
			label="Primary Phone"
		/>,
		<PhoneInputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerWorkPhone}
			name="coBuyerWorkPhone"
			label="Work Phone"
		/>,
		<PhoneInputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerFax}
			name="coBuyerFax"
			label="Fax"
		/>,
		<InputElement
			readOnly={true}
			disableValidation={true}
			value={creditApp.coBuyerDL}
			name="coBuyerDL"
			label="Driver License"
			type="text"
			//onChange={(e) => handleChange("driverLicense", e.target.value)}
		/>,

		<h5 className="col-12 mb-3">Address</h5>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={
				creditApp.coBuyerAddress +
				(creditApp.coBuyerUnit === null ? "" : " - " + creditApp.coBuyerUnit)
			}
			name="coBuyerAddress"
			label="Street"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerCity}
			name="coBuyerCity"
			label="City"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerState}
			name="coBuyerState"
			label="State"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerZipcode}
			name="zip"
			label="Zip"
		/>,

		///////////////////
		<h5 className="col-12 mb-3">Income</h5>,
		<CurrencyInput
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerGrossIncome}
			name="coBuyerGrossIncome"
			label="Income"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerOtherIncome}
			name="coBuyerOtherIncome"
			label="Other Income"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerMonthlyBonuses}
			name="coBuyerMonthlyBonuses"
			label="Monthly Bonuses"
		/>,

		<h5 className="col-12 mb-3">Employment</h5>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerEmployerName}
			name="coBuyerEmployerName"
			label="Work Company Name"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerStartEmployment}
			name="coBuyerStartEmployment"
			label="Emp Start Date"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerEndEmployment}
			name="coBuyerEndEmployment"
			label="Emp End Date"
		/>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			value={creditApp.coBuyerOccupation}
			name="coBuyerOccupation"
			label="Occupation"
		/>,

		<h5 className="col-12 mb-3">Other Informaation </h5>,
		<InputElement
			disableValidation={true}
			readOnly={true}
			label="Source"
			value={creditApp.source}
			options={AdSource}
		/>,
	];

	const vehicle = [
		<InputElement
			disableValidation={true}
			value={creditAppVehicle.stockNo}
			readOnly={true}
			name="stockNumber"
			label="StockNumber"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={creditAppVehicle.vin}
			readOnly={true}
			name="Vin"
			label="Vin"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={creditAppVehicle.year}
			readOnly={true}
			name="year"
			label="Year"
			type="text"
		/>,

		<InputElement
			disableValidation={true}
			value={creditAppVehicle.make}
			readOnly={true}
			name="make"
			label="Make"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={creditAppVehicle.model}
			readOnly={true}
			name="model"
			label="Model"
			type="text"
		/>,
		<InputElement
			disableValidation={true}
			value={creditAppVehicle.color}
			readOnly={true}
			name="color"
			label="Color"
			type="text"
		/>,
	];

	const getVehicleData = (inventoryID) => {
		getVehicleDetails(inventoryID).then(
			(res) => {
				console.log(res.data.content);
				setCreditAppVehicle(res.data.content);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	const scoreHandler = (score) => {
		if (score === null || score === undefined) {
			return "None";
		} else if (score >= 0) {
			return score;
		} else {
			return "None";
		}
	};

	const PrescreenModals = () => {
		return (
			<>
				<ChildrenModal
					modal={xpnModal}
					toggle={xpnToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={
						<Credit700PreScreen
							bureau="XPN"
							creditApp={creditApp}
							toggle={() => {
								//toggle();
								xpnToggle();
							}}
						/>
					}
				/>
				<ChildrenModal
					modal={tuModal}
					toggle={tuToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={
						<Credit700PreScreen
							bureau="TU"
							creditApp={creditApp}
							toggle={() => {
								//toggle();
								tuToggle();
							}}
						/>
					}
				/>
				<ChildrenModal
					modal={efxModal}
					toggle={efxToggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={
						<Credit700PreScreen
							bureau="EFX"
							creditApp={creditApp}
							toggle={() => {
								//toggle();
								efxToggle();
							}}
						/>
					}
				/>
			</>
		);
	};

	useEffect(() => {
		if (creditApp.inventoryID > 0) {
			getVehicleData(creditApp.inventoryID);
		}
		setStatus(creditApp.active ? 1 : 0);

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		console.log(creditApp);
		console.log(creditApp.creditAppID);
		console.log(creditApp.active);
		console.log(status);

		updateCreditAppStatus(creditApp.creditAppID, status ? 1 : 0);
		creditApp.active = status;

		console.log(creditApp);
		console.log(creditApp.active);
		// eslint-disable-next-line
	}, [status]);

	return (
		<>
			<div>
				<Row className="mx-0">
					<Col className="pb-3 text-center mt-3">
						<CardTitle>
							<h2>Credit Application</h2>
						</CardTitle>
					</Col>
				</Row>
			</div>

			<Row>
				<SelectorElement
					disableValidation={true}
					value={status}
					name="CreditAppStatus"
					label="Credit Application Status"
					onChange={(e) => setStatus(Number(e.target.value))}
					options={OnlineCreditAppStatus}
				/>
				<div className="col-xl-2">
					<Card className="zoom cursor-pointer">
						<CardBody onClick={xpnToggle} className="text-center">
							<img src={Experian} alt="experian" className="rounded" />
							Score: {scoreHandler(creditApp.xpnScore)}
						</CardBody>
					</Card>
				</div>
				<div className="col-xl-2">
					<Card className="zoom cursor-pointer">
						<CardBody onClick={tuToggle} className="text-center">
							<img src={Transunion} alt="transunion" className="rounded" />
							Score: {scoreHandler(creditApp.tuScore)}
						</CardBody>
					</Card>
				</div>
				<div className="col-xl-2">
					<Card className="zoom cursor-pointer">
						<CardBody onClick={efxToggle} className="text-center">
							<img src={Equifax} alt="equifax" className="rounded" />
							Score: {scoreHandler(creditApp.efxScore)}
						</CardBody>
					</Card>
				</div>
				<PrescreenModals />
				{!isCreditSignup && (
					<div className="col-xl-4">
						<Card className="zoom cursor-pointer">
							<a
								href="https://700credit.com/dealerclick"
								target="_blank"
								rel="noopener noreferrer"
								className=""
							>
								<CardBody>
									<img src={Credit} alt="700credit" className="rounded" />
									<div className="p text-center">Sign Up</div>
								</CardBody>
							</a>
						</Card>
					</div>
				)}
			</Row>

			<InputWrapper
				formTitle={"Applicant " + (dev ? String(creditApp.customerID) : "")}
				inputComponents={buyer}
			/>
			<InputWrapper
				formTitle={
					"CO-Applicant " + (dev ? String(creditApp.coBuyerCustomerID) : "")
				}
				inputComponents={cobuyer}
			/>
			<InputWrapper
				formTitle={"Vehicle " + (dev ? String(creditApp.inventoryID) : "")}
				inputComponents={vehicle}
			/>
			<DeleteCreditAppButton IDs={creditApp.creditAppID} toggle={toggle} />
		</>
	);
};

export default OnlineCreditAppDetailsPage;
