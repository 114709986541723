import useSetAccount from "./useSetAccount";
import { saveAccount } from "./AccountAPI";
import useBuildAccount from "./useBuildAccount";

const AccountHook = () => {
	const setAccount = useSetAccount();
	const getAcct = useBuildAccount();

	const save = () => {
		const acctObj = getAcct();
		console.log(acctObj);
		saveAccount(acctObj).then(
			(res) => {
				console.log(res);
				setAccount(res);
			},
			(err) => {}
		);
	};

	return save;
};

export default AccountHook;
