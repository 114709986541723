import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import {
	getDealerInsuranceInfo,
	getPartnerLinks,
	getPartnerSend,
	linkUACDealer,
} from "api/DealerIntegrationApi";
import { useFromUser } from "stores/LocalStorageHelper";
import { showApiError } from "utils/errorRoutingUtils";
import {
	retrieveFailAlert,
	dealerBondAlert,
	failAlert,
	saveSuccessAlert,
	contactUAC,
} from "utils/alertUtils";
import FlooringRatioTable from "./FlooringRatioTable";
import dayjs from "dayjs";
import SignUps from "../../settings/subFeatures/settingsTabs/signUp/SignUps";
import useCurrentDealerLinkStore from "stores/DealerLinkStore";
import shallow from "zustand/shallow";
import {
	getAllStandardForms,
	getAllStateForms,
	getAllCustomForms,
	getAllInventoryForms,
	getAllTradeForms,
	getAllInventoryReports,
	getAllManagementReports,
	getAllSalesReports,
	getAllCustomReports,
	getAllBHPHReports,
	getAllBHPHForms,
	getAllOkiForms,
} from "api/FormsAPI";
import { getDealerTempList } from "api/SettingsAPI";
import { useHistory } from "react-router-dom";
import {
	getDealerMakeList,
	getDealerModelList,
	getVehicleSettings,
} from "api/InventoryAPI";
import ArticleCard from "./ArticleCard";
const SESSION_STORAGE_FLAG = "DEALER_BOND_ALERT";

const shouldShowDealerBondAlert = () => {
	return sessionStorage.getItem(SESSION_STORAGE_FLAG) !== "true";
};

const DashboardSection = () => {
	const { uacdealerID } = useCurrentDealerLinkStore((state) => state, shallow);
	const locationID = useFromUser("locationID");
	const dealerID = useFromUser("dealerID");
	const taxID = useFromUser("taxID");
	const [uacc, setUacc] = useState(0);
	const history = useHistory();

	useEffect(() => {
		Promise.all([
			getDealerInsuranceInfo(locationID),
			getPartnerSend(locationID),
			getPartnerLinks(locationID),
		]).then(
			(res) => {
				console.log(res);
				const dlrInfo = res[0].data.content;
				if (
					shouldShowDealerBondAlert() &&
					(dlrInfo.dbiExpDate === null ||
						dlrInfo.dbiExpDate === "" ||
						dayjs(dlrInfo.dbiExpDate).isValid === false ||
						dlrInfo.buiExpDate === null ||
						dlrInfo.buiExpDate === "" ||
						dayjs(dlrInfo.buiExpDate).isValid === false)
				) {
					dealerBondAlert(history);
				}
				setUacc(res[1].data.content.useUACC);
				const partnerSend = res[1].data.content;
				const partnerLink = res[2].data.content.dealerPartnerLink;
				const isCreditSignup = res[2].data.content.isCreditSignup;
				sessionStorage.setItem(
					"dealerPartnerSend",
					JSON.stringify(partnerSend)
				);
				sessionStorage.setItem(
					"dealerPartnerLink",
					JSON.stringify({ ...partnerLink, isCreditSignup })
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	}, [locationID, history]);

	useEffect(() => {
		if (dealerID && locationID) {
			const params = { dealerID, locationID };
			Promise.all([
				getAllStandardForms(params),
				getAllStateForms(params),
				getAllCustomForms(params),
				getAllInventoryForms(params),
				getAllTradeForms(params),
				getAllInventoryReports(params),
				getAllManagementReports(params),
				getAllSalesReports(params),
				getAllCustomReports(params),
				getAllBHPHReports(params),
				getAllBHPHForms(params),
				getAllOkiForms(params),
				getDealerTempList(locationID),
				getDealerMakeList(dealerID, locationID),
				getDealerModelList(dealerID, locationID),
				getVehicleSettings(locationID),
			]).then(
				(res) => {
					let formsReports = {
						standardForms: res[0].data.content,
						stateForms: res[1].data.content,
						customForms: res[2].data.content,
						inventoryForms: res[3].data.content,
						tradeForms: res[4].data.content,
						inventoryReports: res[5].data.content,
						managementReports: res[6].data.content,
						salesReports: res[7].data.content,
						customReports: res[8].data.content,
						bhphReports: res[9].data.content,
						bhphForms: res[10].data.content,
						okiForms: res[11].data.content,
					};
					const makes = res[13].data.content.map((make) => {
						return { label: make.make, value: make.make, obj: make };
					});
					const models = res[14].data.content.map((model) => {
						return { label: model.model, value: model.model, obj: model };
					});
					sessionStorage.setItem("dealersList", JSON.stringify(res[12].data));
					sessionStorage.setItem("formsReports", JSON.stringify(formsReports));
					sessionStorage.setItem("makes", JSON.stringify(makes));
					sessionStorage.setItem("models", JSON.stringify(models));
					sessionStorage.setItem(
						"inventoryDefaults",
						JSON.stringify(res[15].data.content)
					);
				},
				(err) => {
					if (!err.isGeneralError) {
						console.log(err);
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	}, [dealerID, locationID]);

	const toggleUacApi = () => {
		if (!taxID || taxID === "") {
			failAlert("No taxID found, contact DealerClick to add taxID...");
		} else {
			console.log(taxID);
			linkUACDealer(locationID).then(
				(res) => {
					let link = JSON.parse(sessionStorage.getItem("dealerPartnerLink"));
					link.uacdealerID = res.data.UaccDealerId;
					sessionStorage.setItem("dealerPartnerLink", JSON.stringify(link));
					saveSuccessAlert("UAC linked!");
				},
				(err) => {
					contactUAC(err.response.data.message);
				}
			);
		}
	};

	return (
		<>
			<FlooringRatioTable />
			<Row className="mt-3">
				<Col md="6" className="mt-3">
					<div className="px-3 w-100">
						<h3>
							<span aria-label="gear emoji" role="img">
								⚙️{" "}
							</span>
							Featured Integrations
						</h3>
						<hr className="mb-2" style={{ width: "100%" }} />
					</div>
					<SignUps
						showUAC={uacc === 1 && uacdealerID.length > 0}
						toggleUacApi={toggleUacApi}
					/>
				</Col>
				<Col md="6" className="mt-3" style={{ borderLeft: "1px solid #ddd" }}>
					<div className="px-3 w-100">
						<h3>
							<span aria-label="paper emoji" role="img">
								📄{" "}
							</span>
							Latest Tips & Tricks
						</h3>
						<hr className="mb-2" style={{ width: "100%" }} />
					</div>
					<div className="d-flex flex-wrap justify-content-center">
						<ArticleCard
							title="Navigating the Latest CAR Regulations: A Comprehensive Guide for Dealerships"
							description="Explore the impact of new CAR regulations on auto dealerships and how to stay compliant. Essential insights for modern auto business management."
							image="https://dealerclick.com/static/4819786fde386ad8807d87fba4599a4d/fb23b/what-new-car-rules-mean-for-auto-dealerships.webp"
							link="https://dealerclick.com/blog/what-new-car-rules-mean-for-auto-dealerships/"
						/>
						<ArticleCard
							title="Automotive Market Trends for 2024"
							description="Discover 2024's automotive trends, evolving sales, electric vehicles (EVs) growth, and the impact of consumer preferences and technology in retail."
							image="https://dealerclick.com/static/d588b701e685a3110761bccc26e380b2/fb23b/automotive-market-trends-2024.webp"
							link="https://dealerclick.com/blog/automotive-market-trends-2024/"
						/>
						<ArticleCard
							title="Enhancing F&I Sales: Six Proven Strategies for Success"
							description="Discover 2024's automotive trends, evolving sales, electric vehicles (EVs) growth, and the impact of consumer preferences and technology in retail."
							image="https://dealerclick.com/static/50a7202eec250d91888de156c6abe3b1/fb23b/6-tips-to-sell-more-f-and-i-products.webp"
							link="https://dealerclick.com/blog/6-tips-to-sell-more-f-and-i-products/"
						/>
						<ArticleCard
							title="Automotive Market Trends for 2024"
							description="Discover 2024's automotive trends, evolving sales, electric vehicles (EVs) growth, and the impact of consumer preferences and technology in retail."
							image="https://dealerclick.com/static/d588b701e685a3110761bccc26e380b2/fb23b/automotive-market-trends-2024.webp"
							link="https://dealerclick.com/blog/automotive-market-trends-2024/"
						/>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default DashboardSection;
