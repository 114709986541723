import React from "react";
import useCurrentDealStore from "stores/DealStore";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import { saveDeal } from "api/DealAPI";
import { saveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import NoteButton from "features/notes/NoteButton";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";
import useSetDeal from "features/deal/hooks/useSetDeal";

const NotesButton = () => {
	const { ID, type, notes, editNotes } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			type: state.deal.type,
			notes: state.deal.notes,
			editNotes: state.editNotes,
		}),
		shallow
	);
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	// If the deal exists, save the deal obj when the notes are updated
	const saveNotes = () => {
		if (ID != null) {
			const deal = getDeal();
			let modifiedDeal = { ...deal };
			const modifiedNotes = notes.length > 0 ? JSON.stringify(notes) : "[]";
			modifiedDeal.deal.notes = modifiedNotes;
			saveDeal(modifiedDeal).then(
				(response) => {
					setDeal(response.data.content);
					console.log(response.data.content.deal.notes);
					console.log("Deal saved with ID: " + response.data.content.deal.ID);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
	};

	return (
		<NoteButton
			disableAddAndSave={type !== DealStatus.PENDING}
			notes={notes}
			editNotes={editNotes}
			saveNotes={saveNotes}
		/>
	);
};

export default NotesButton;
