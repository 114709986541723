import React, { useState, useEffect, useRef } from "react";
import { Row } from "reactstrap";
import IDPhoto from "assets/img/placeholder.png";

import shallow from "zustand/shallow";
//import { useFromUser } from "../store/LocalStorageHelper";

import useCurrentAccountStore from "./AccountStore";

import { getCustomerPhoto } from "api/AttachmentsAPI";
import { useFromUser } from "stores/LocalStorageHelper";
import ChildrenModal from "components/modals/ChildrenModal";
import OpenModalButton from "../features/deal/components/OpenModalButton";
import CustomerDealDetails from "../features/deal/subFeatures/customer/CustomerDealDetails";
import ContactAddPage from "../features/crm/components/CustomerAddPage";
import CustomerListPage from "../features/crm/subFeatures/contactListPage/CustomerListPage";

const AccountBuyerHeader = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	// Account store
	const { buyer, customerID } = useCurrentAccountStore(
		(state) => ({
			buyer: state.buyer,
			customerID: state.account.buyerRecNum,
		}),
		shallow
	);
	const [profilePictureSrc, setProfilePictureSrc] = useState(null);

	const { ID, firstName, lastName } = buyer || "";
	// Converts the bytes of a file
	const setCustomerPhotoSrc = (byteArray) => {
		console.log(byteArray);
		if (byteArray.byteLength > 0) {
			const blob2File = new Blob([byteArray]);
			const blobUrl = URL.createObjectURL(blob2File);

			setProfilePictureSrc(blobUrl);
			console.log(blobUrl);
		} else {
			setProfilePictureSrc(IDPhoto);
		}
	};
	console.log(buyer);
	useEffect(() => {
		if (customerID !== null) {
			getCustomerPhoto(dealerID, locationID, customerID).then(
				(res) => {
					setCustomerPhotoSrc(res.data);
				},
				(err) => console.log(err)
			);
		} else {
			setCustomerPhotoSrc(IDPhoto);
		}
	}, [customerID, dealerID, locationID]);

	return (
		<div>
			{(ID == null && (
				<>
					<Row>
						<BuyerModal />
					</Row>
				</>
			)) || (
				<Row className="">
					<div className="mx-auto w-100">
						<h4 className="text-center mb-2 mt-0">
							{firstName + " " + lastName}
						</h4>

						<img
							className="customer-profile pt-1 pb-3"
							src={profilePictureSrc}
							alt="customer-profile"
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = IDPhoto;
							}}
						/>

						<BuyerModal />
					</div>
				</Row>
			)}
		</div>
	);
};

export default AccountBuyerHeader;

const BuyerModal = () => {
	// Local states
	const isMounted = useRef(false);
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [newModal, setNewModal] = useState(false);

	// Deal store
	const {
		buyerRecNum,
		//		type,
		editBuyer,
		editBuyerRecNum,
		editBuyerAddress,
		editBuyerMailAddress,
	} = useCurrentAccountStore(
		(state) => ({
			buyerRecNum: state.account.buyerRecNum,
			//			type: state.account.type,
			editBuyer: state.editBuyer,
			editBuyerRecNum: state.editBuyerRecNum,
			editBuyerAddress: state.editBuyerAddress,
			editBuyerMailAddress: state.editBuyerMailAddress,
		}),
		shallow
	);

	// Modal toggle functions
	const toggleAdd = () => {
		if (isMounted.current) {
			setAddModal(!addModal);
		}
	};

	const toggleEdit = () => {
		setEditModal(!editModal);
	};

	const toggleNew = () => {
		setNewModal(!newModal);
	};

	const toggleDelete = () => {
		editBuyerRecNum(null);
		editBuyer({});
	};

	// Update deal store with new customer's info
	const updateNewCustomer = (data) => {
		editBuyerRecNum(data.customer.ID);
		editBuyer(data.customer);
		editBuyerAddress(data.currentAddress);
		editBuyerMailAddress(data.mailingAddress);
		toggleNew();
	};

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	return (
		<>
			{(buyerRecNum == null && (
				<div className="mx-auto">
					<OpenModalButton
						buttonClass="btn-sm"
						buttonColor="secondary"
						onClick={toggleNew}
						buttonText="Add"
						iconClass="nc-icon nc-simple-add"
					/>
					<OpenModalButton
						buttonClass="btn-sm"
						buttonColor="secondary"
						onClick={toggleAdd}
						buttonText="Select"
						icon={true}
						iconClass="nc-icon nc-tap-01"
					/>
				</div>
			)) || (
				<div className="mx-auto">
					<OpenModalButton
						iconClass="nc-icon nc-align-center"
						buttonClass="btn-sm btn-primary"
						onClick={toggleEdit}
						buttonText="Edit"
					/>
					<OpenModalButton
						buttonClass="btn-link-danger text-danger btn-sm text-danger"
						onClick={toggleDelete}
						iconClass="nc-icon nc-simple-remove"
						buttonColor="link"
						buttonText="Remove"
					/>
				</div>
			)}
			<ChildrenModal
				modal={newModal}
				toggle={toggleNew}
				children={
					<ContactAddPage
						updateDeal={updateNewCustomer}
						isDeal={true}
						isBuyer={true}
					/>
				}
			/>
			<ChildrenModal
				modal={addModal}
				toggle={toggleAdd}
				modalTitle="Select Contact"
				children={
					<CustomerListPage
						isDeal={true}
						toggle={toggleAdd}
						editCustomer={editBuyer}
						editAddress={editBuyerAddress}
						editMailAddress={editBuyerMailAddress}
						editRecNum={editBuyerRecNum}
					/>
				}
			/>
			<ChildrenModal
				modal={editModal}
				toggle={toggleEdit}
				modalTitle="Edit Current Contact"
				children={
					<CustomerDealDetails
						ID={buyerRecNum}
						editCustomer={editBuyer}
						editAddress={editBuyerAddress}
						editMailAddress={editBuyerMailAddress}
						editRecNum={editBuyerRecNum}
						toggle={toggleEdit}
						cloneCustomer={false}
						isBuyer={true}
						type="BHPH"
					/>
				}
			/>
		</>
	);
};
