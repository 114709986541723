import swal from "sweetalert2";
import history from "utils/createBrowserHistory";
import { PATHS } from "constants/Constants";
import { refreshToken } from "api/LoginAPI";


export const saveSuccessAlert = (title) => {
	const saveToast = swal.mixin({
		toast: true,
		position: "bottom",
		showConfirmButton: false,
		timer: 2000,
		didOpen: (toast) => {
			toast.addEventListener("mouseleave", swal.resumeTimer);
		},
	});

	saveToast.fire({
		icon: "success",
		title: `${title || "Saved Successfully!"}`,
	});
};

export const miniUpdateSuccessAlert = (title) => {
	const saveToast = swal.mixin({
		toast: true,
		position: "bottom",
		showConfirmButton: false,
		timer: 2000,
		didOpen: (toast) => {
			toast.addEventListener("mouseleave", swal.resumeTimer);
		},
	});

	saveToast.fire({
		icon: "success",
		title: `${title || "Update Successfully!"}`,
	});
};

export const updateSuccessAlert = () => {
	swal.fire({
		title: "Save Successful!",
		text: "Saved Successfully!",
		icon: "success",
		confirmButtonText: "Ok",
	});
};

export const saveFailAlert = (msg) => {
	swal.fire({
		title: "Error!",
		text: msg || "Save Unsuccessful!",
		confirmButtonText: "Ok",
	});
};

export const saveFailAlertMessage = (msg) => {
	swal.fire({
		title: "Error!",
		text: msg,
		confirmButtonText: "Ok",
	});
};

export const loginFailAlert = () => {
	swal.fire({
		title: "Error!",
		text: "Login failed, incorrect username or password...",
		confirmButtonText: "Ok",
	});
};

export const confirmDeleteAlert = (apiCall, ID, index) => {
	swal
		.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",

			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		})
		.then((result) => {
			if (result.isConfirmed) {
				apiCall(ID, index);
			}
		});
};

export const deleteSuccessAlert = () => {
	swal.fire("Deleted!", "Your file has been deleted.", "success");
};

export const deleteFailAlert = () => {
	swal.fire("Error!", "Failed to Delete");
};

export const deleteFailAlertWithText = (text) => {
	swal.fire("Error!", text);
};

export const retrieveFailAlert = (msg) => {
	swal.fire("Error!", msg || "Failed to Retrieve Data");
};

export const idleAlert = () => {
	let timerInterval;
	swal
		.fire({
			title: "Session Expiring...",
			html: "You will be logged out due to inactivity in <b></b>",
			allowOutsideClick: false,
			timer: 1000 * 60 * 10,
			timerProgressBar: true,
			willOpen: () => {
				timerInterval = setInterval(() => {
					const content = swal.getContent();
					if (content) {
						const b = content.querySelector("b");
						if (b) {
							const timeLeft = () => {
								const minutes = Math.floor(swal.getTimerLeft() / (1000 * 60));
								const seconds =
									Math.floor(swal.getTimerLeft() / 1000) - minutes * 60;
								return minutes + ":" + seconds;
							};
							b.textContent = timeLeft();
						}
					}
				}, 1000);
			},
			willClose: () => {
				clearInterval(timerInterval);
			},
			showConfirmButton: true,
			confirmButtonColor: "#3085d6",
			confirmButtonText: "Stay logged in!",
		})
		.then((result) => {
			if (result.isConfirmed) {
				// get new jwt token
				refreshToken();
			} else {
				// log the user out
				console.log("logging out");
				sessionStorage.removeItem("user");
				history.push(PATHS.LOGIN);
				sessionExpiredAlert();
			}
		});
};

export const sessionExpiredAlert = () => {
	swal.fire("Logged Out", "Your have been logged out due to inactivity");
};

export const userConflictAlert = () => {
	swal.fire(
		"Logged Out",
		"Your have been logged out because another user has logged in with your account"
	);
};

export const failAlert = (text) => {
	swal.fire("Error!", text);
};

export const invalidDealAlert = () => {
	swal.fire(
		"Must have a valid deal!",
		"Save a vehicle and a customer to this deal in order to access..."
	);
};

export const successAlertCustom = (text) => {
	swal.fire({
		title: "Successful!",
		text,
		icon: "success",
		confirmButtonText: "Ok",
	});
};

export const confirmNewUrl = (url) => {
	swal
		.fire({
			text: "Log in to your Carfax account to get the report.",

			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Log In",
		})
		.then((result) => {
			if (result.isConfirmed) {
				window.location.assign(url);
			}
		});
};

export const twoOptionsAlert = async (title, buttonText1, buttonText2) => {
	return swal
		.fire({
			title,
			showDenyButton: true,
			confirmButtonText: buttonText1,
			denyButtonText: buttonText2,
			confirmButtonColor: "#3085d6",
			denyButtonColor: "#d33",
		})
		.then((result) => {
			if (result.isConfirmed) {
				return buttonText1;
			} else if (result.isDenied) {
				return buttonText2;
			} else if (result.isDismissed) {
				return "Cancel";
			}
		});
};

export const confirmDeleteMessage = async (buttonText1) => {
	return swal
		.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",

			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: buttonText1,
		})
		.then((result) => {
			if (result.isConfirmed) {
				return buttonText1;
			} else if (result.isDismissed) {
				return "Cancel";
			}
		});
};

export const showSwalMessage = (title, body, icon) => {
	swal.fire(title, body, icon);
};

export const confirmDeleteTradeAlert = (apiCall, ID, index) => {
	swal
		.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",

			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		})
		.then((result) => {
			if (result.isConfirmed) {
				apiCall(ID, index);
			}
		});
};

export const confirmTransferToSold = (func) => {
	swal
		.fire({
			text: "This deal does not have a vehicle!",

			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Continue",
		})
		.then((result) => {
			if (result.isConfirmed) {
				func();
			}
		});
};

export const continueOpenForm = () => {
	swal.fire({
		text: "This deal does not have a vehicle!",
	});
};

export const contactUAC = (text) => {
	swal.fire({
		title: "Failed to link",
		text: text,
		willClose: () => {
			window.open("https://www.unitedautocredit.net/contact.aspx");
		},
		showConfirmButton: true,
		confirmButtonColor: "#3085d6",
	});
};

export const loading = (title) => {
	swal.fire({
		title: title,
		showConfirmButton: false,
		willOpen() {
			swal.showLoading();
		},
		didClose() {
			swal.hideLoading();
		},
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
	});
};

const SESSION_STORAGE_FLAG = "DEALER_BOND_ALERT";/**
 * Displays an alert for Dealer Insurance Information.
 * 
 * @param {object} history - History object for navigation.
 */
export const dealerBondAlert = (history) => {
	swal
	  .fire({
		title: "Alert!",
		text: "Please complete your Dealer Insurance Information",
		showCancelButton: true,
		confirmButtonColor: "#d33",
		cancelButtonColor: "#3085d6",
		confirmButtonText: "Continue",
		cancelButtonText: "Close and Hide This Message",
	  })
	  .then((result) => {
		if (result.isConfirmed) {
		  // Redirect to the dealer bond settings page
		  history.push(PATHS.SETTINGS, { tab: "dealerBond" });
		} else if (result.dismiss === swal.DismissReason.cancel) {
		  // If the user clicks "Close and Hide This Message," set the flag in session storage
		  sessionStorage.setItem(SESSION_STORAGE_FLAG, "true");
		}
	  });
  };