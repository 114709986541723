import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";

/**
 * ChildrenModal component renders a customizable modal dialog.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.modal - Controls the modal's visibility.
 * @param {Function} props.toggle - Function to toggle the modal visibility.
 * @param {string} [props.modalTitle] - Title text displayed in the modal header.
 * @param {React.ReactNode} props.children - The content to be displayed within the modal body.
 * @param {Function} [props.onClosed] - Function to be called when the modal is closed.
 * @param {string} [props.size=xl] - Size of the modal (e.g., 'sm', 'lg', 'xl').
 * @param {boolean} [props.hideX=false] - Flag to hide or show the close button in the modal header.
 * @returns {JSX.Element} The rendered modal component.
 */
const ChildrenModal = ({
	modal,
	toggle,
	modalTitle,
	bodyClass,
	children,
	onClosed,
	size = "xl",
	hideX = false,
}) => {
	/**
	 * Handles the modal toggle event.
	 *
	 * @param {React.MouseEvent} [e] - The event triggered by clicking the close button.
	 */
	const handleToggle = (e) => {
		if (e) {
			e.stopPropagation();
		}
		toggle();
	};

	return (
		<Modal
			toggle={handleToggle}
			isOpen={modal}
			size={size}
			backdrop="static"
			onClosed={onClosed}
			onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing dropdown
		>
			{!hideX && (
				<div className="modal-header justify-content-center">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleToggle}
					>
						<span aria-hidden="true">×</span>
					</button>
					<h5 className="modal-title">{modalTitle}</h5>
				</div>
			)}
			<ModalBody className={bodyClass}>{children}</ModalBody>
		</Modal>
	);
};

ChildrenModal.propTypes = {
	modal: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	modalTitle: PropTypes.string,
	children: PropTypes.node.isRequired,
	onClosed: PropTypes.func,
	size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
	hideX: PropTypes.bool,
};

export default ChildrenModal;
