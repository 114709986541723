import React from "react";
import { Col, Row, Label, Form } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import tabOnEnter from "../utils/formNavigationUtils";
import InputElement, {
	CurrencyInput,
} from "../features/forms/components/InputElement";

const AccountBalance = () => {
	const { account } = useCurrentAccountStore((state) => state, shallow);
	return (
		<>
			<Col className="pb-3 px-0 d-flex align-items-center justify-content-center">
				<div>
					<label></label>
					<h4>Loan Balance</h4>
				</div>
			</Col>

			<Form>
				<Row className="mx-0 pt-0">
					<div className="col-xl-3 mb-2">
						<Label></Label>
					</div>
					<div className="col-xl-3 mb-2">
						<Label>Total</Label>
					</div>
					<div className="col-xl-3 mb-2">
						<Label>Paid To date</Label>
					</div>
					<div className="col-xl-3 mb-2">
						<Label>Remainder</Label>
					</div>
				</Row>
				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<div className="col-xl-3 mb-2">
						<Label>Principel(Loan)</Label>
					</div>
					<CurrencyInput
						wholeRow
						value={account.amtFinanced}
						readOnly={true}
						name="amtFinanced"
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						value={account.principle}
						readOnly={true}
						name="principle"
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						value={account.principleRemaining}
						readOnly={true}
						name="principleRemaining"
						type="Currency"
						disableValidation
					/>
				</Row>
				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<div className="col-xl-3 mb-2">
						<Label>Finance Charge</Label>
					</div>
					<CurrencyInput
						wholeRow
						value={account.financeCharge}
						readOnly={true}
						name="financeCharge"
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						value={account.interest}
						readOnly={true}
						name="interest"
						type="Currency"
						disableValidation
					/>

					<CurrencyInput
						wholeRow
						value={account.financeCharge - account.interest}
						readOnly={true}
						name="remFinanceCharge"
						type="Currency"
						disableValidation
					/>
				</Row>
				{account.isTaxDeferred === 1 && (
					<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
						<div className="col-xl-3 mb-2">
							<Label>Tax </Label>
						</div>
						<CurrencyInput
							wholeRow
							value={account.tax}
							readOnly={true}
							name="tax"
							type="Currency"
							disableValidation
						/>
						<CurrencyInput
							wholeRow
							value={account.taxPaid}
							readOnly={true}
							name="taxPaid"
							type="Currency"
							disableValidation
						/>

						<CurrencyInput
							wholeRow
							value={account.tax - account.taxPaid}
							readOnly={true}
							name="remainTax"
							type="Currency"
							disableValidation
						/>
					</Row>
				)}
				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<div className="col-xl-3 mb-2">
						<Label>Adjustment</Label>
					</div>
					<CurrencyInput
						wholeRow
						value={account.debitCreditTotal}
						readOnly={true}
						name="debitCreditTotal"
						type="Currency"
						disableValidation
					/>

					<CurrencyInput
						wholeRow
						value={account.adjPaid}
						readOnly={true}
						name="adjPaid"
						type="Currency"
						disableValidation
					/>

					<CurrencyInput
						wholeRow
						value={account.totalDebitCredit}
						readOnly={true}
						name="totalDebitCredit"
						type="Currency"
						disableValidation
					/>
				</Row>
				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<div className="col-xl-3 mb-2">
						<Label>Late Fee</Label>
					</div>
					<CurrencyInput
						wholeRow
						value={account.totalLateFees}
						readOnly={true}
						name="totalLateFees"
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						value={account.latePaid}
						readOnly={true}
						name="lateFeesPaid"
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						value={account.lateFeeDue}
						readOnly={true}
						name="lateFeeDue"
						type="Currency"
						disableValidation
					/>
				</Row>
				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<div className="col-xl-3 mb-2">
						<Label>Deferred(Pickup)</Label>
					</div>
					<CurrencyInput
						wholeRow
						value={account.pickupTotal}
						readOnly={true}
						name="pickupTotal"
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						value={account.pickupPaid}
						readOnly={true}
						name="pickupPaid"
						type="Currency"
						disableValidation
					/>

					<CurrencyInput
						wholeRow
						value={account.pickupTotal - account.pickupPaid}
						readOnly={true}
						name="pickupRemain"
						type="Currency"
						disableValidation
					/>
				</Row>
				{/* {account.penaltyInt > 0 && ( */}
				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<div className="col-xl-3 mb-2">
						<Label>Additional Interest </Label>
					</div>
					<CurrencyInput
						wholeRow
						readOnly={true}
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						value={account.earnedInterest}
						readOnly={true}
						name="earnedInterest"
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						readOnly={true}
						type="Currency"
						disableValidation
					/>
				</Row>
				{/* )} */}
				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<div className="col-xl-3 mb-2">
						<Label>Total </Label>
					</div>
					<CurrencyInput
						wholeRow
						value={
							account.amtFinanced +
							account.financeCharge +
							account.debitCreditTotal +
							account.totalLateFees +
							account.pickupTotal
						}
						readOnly={true}
						name="totalToPay"
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						value={
							account.principle +
							account.interest +
							account.adjPaid +
							account.latePaid +
							account.pickupPaid
						}
						readOnly={true}
						name="totalPayTodate"
						type="Currency"
						disableValidation
					/>
					<CurrencyInput
						wholeRow
						value={
							account.amtFinanced +
							account.financeCharge +
							account.debitCreditTotal +
							account.totalLateFees +
							account.pickupTotal -
							(account.principle +
								account.interest +
								account.adjPaid +
								account.latePaid +
								account.pickupPaid)
						}
						readOnly={true}
						name="totalPayTodate"
						type="Currency"
						disableValidation
					/>
				</Row>
				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<div className="col-xl-3 mb-2">
						<Label>Break Even </Label>
					</div>
					<InputElement
						wholeRow
						disableValidation
						readOnly={true}
						value={account.breakEven}
						name="breakEvenDate"
						label="Date"
						type="date"
						colSize="col-xl-12"
					/>

					<CurrencyInput
						wholeRow
						disableValidation
						label="Cost"
						colSize="col-xl-6 mb-2"
						readOnly={true}
						type="currency"
						name="cost"
						value={account.totalCost}
					/>
					<CurrencyInput
						wholeRow
						disableValidation
						label="Break Even"
						colSize="col-xl-6 mb-2"
						readOnly={true}
						type="currency"
						name="BrakeEven"
						value={
							account.totalCost - account.principle > 0
								? account.totalCost - account.principle
								: 0
						}
					/>
				</Row>
			</Form>
			{/* <Col className="pb-3 px-0 d-flex align-items-center justify-content-center">
				<div className="col-xl-6 mb-2">
				</div>

				<div>
					<label ></label>
					<h5>
						Down Payment:
						<NumberFormat
							value={account.downPmt}
							displayType={"text"}
							thousandSeparator={true}
							prefix={"$"}
						/>
					</h5>
					<CurrencyInput
						wholeRow
						value={account.amtFinanced + account.principle}
						readOnly={true}
						name="BrakeEven"
						type="Currency"
						disableValidation
					/>
				</div>
			</Col> */}
		</>
	);
};

export default AccountBalance;
