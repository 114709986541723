/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

import history from "utils/createBrowserHistory";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { getAccountListByStatus } from "./AccountAPI";
import Camera from "assets/img/placeholder.png";
import dayjs from "dayjs";
import AccountHeader from "./AccountHeader";
import { LoanPeriod, PATHS, SaleType } from "constants/Constants";
import AccountTable from "./AccountTable";
import useRoute from "hooks/useRoute";
import Loading from "components/loadingSpinner/Loading";
//import AccountDetailPage from "./AccountDetailPage";
const AccountListPage = () => {
	// Routing
	const [statusList, setStatusList] = useState(0);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const homeRoute = useRoute(PATHS.DASHBOARD);
	const accountDetailPage = useRoute(PATHS.ACCT_DETAIL);

	// Local state
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const currencyFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD", // Specify the currency code
	});

	const dataFormat = (content) => {
		console.log(content);
		const container = Object.keys(content).map((key) => {
			let container = {};
			console.log(content[key]);

			container.thumbnail = (
				<div>
					<img
						className="zoom"
						alt="vehicle"
						src={
							content[key].thumbnail
								? `${content[key].thumbnail}?lastmod=${Date.now()}`
								: Camera
						}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
						width="250"
						height="150"
					/>
				</div>
			);

			container.buyerID = content[key].buyerID;
			container.coBuyerID = content[key].coBuyerID;
			container.inventoryID = content[key].inventoryID;
			container.statusLabel = content[key].statusLabel;
			container.phone = content[key].phone != null ? content[key].phone : "";
			container.fullName = content[key].name;
			container.cofullName = content[key].cobuyer;
			container.email = content[key].email;
			container.customerDetails =
				container.fullName +
				" " +
				container.email +
				" " +
				container.phone +
				" " +
				(content[key].coBuyerID ? "coBuyer: " + content[key].cobuyer : "");
			container.vehicle = content[key].vehicle;
			container.stock = content[key].stock;
			container.vin = content[key].vin;
			container.vehicleStatus = content[key].vehicleStatus;
			container.vehicleDetails =
				container.vehicle + " " + container.stock + " " + container.vin;
			container.year = content[key].year;
			container.make = content[key].make;
			container.model = content[key].model;

			container.loanDate = content[key].loanDate
				? content[key].loanDate.substr(0, 10)
				: (container.loanDate = null);

			container.saleType =
				SaleType[content[key].saleType]?.label || "Unknown type";
			container.accountNumber = content[key].accountNumber;
			container.term = content[key].term;
			container.payment = content[key].payment;
			container.loanPeriod = LoanPeriod[content[key].period].label;
			container.account =
				container.lable +
				" " +
				dayjs(container.loanDate).format("MM/DD/YYYY") +
				" " +
				container.saleType +
				" " +
				container.accountNumber +
				" " +
				container.term +
				" " +
				currencyFormatter.format(container.payment) +
				" " +
				container.loanPeriod;
			container.Actions = (
				<>
					<div className="text-center">
						<div
							className="btn-md btn btn-primary"
							onClick={() => {
								history.push(accountDetailPage, {
									acctID: content[key].acctID,
								});

								//getAccountData(content[key].acctID);
							}}
						>
							<i className="icon-big text-center nc-icon nc-paper text-default"></i>
							<span className="noMobile">&nbsp;View</span>
						</div>
					</div>
				</>
			);
			return container;
		});
		return container;
	};

	// Gets a list of all the Accounts for this dealer and location
	const getListData = (statusList) => {
		console.log("ID: " + dealerID + " " + locationID + " " + statusList);
		if (dealerID !== undefined && locationID !== undefined) {
			getAccountListByStatus(dealerID, locationID, statusList).then(
				(response) => {
					console.log(response);
					const result = dataFormat(response.data.content);
					setData(result);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(homeRoute);
					}
				}
			);
		}
	};

	useEffect(() => {
		console.log("statusList: " + statusList);
		setLoadingList(true);
		getListData(statusList);

		// eslint-disable-next-line
	}, [statusList]);

	return (
		<div>
			<AccountHeader setStatusList={setStatusList} />
			{loadingList && <Loading />}
			{!loadingList && <AccountTable data={data} />}
		</div>
	);
};

export default AccountListPage;
