import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";

const AccountTitle = () => {
	// Form Names
	// Vehicle store
	// const { stock, vin, year, make, model } = useCurrentVehicleStore(
	// 	(state) => ({
	// 		stock: state.stockNo,
	// 		vin: state.vin,
	// 		year: state.year,
	// 		make: state.make,
	// 		model: state.model,
	// 	}),
	// 	shallow
	// );
	// Deal store
	const { buyer, account } = useCurrentAccountStore((state) => state, shallow);
	const { firstName = "", lastName = "" } = buyer || "";

	return (
		<div className="col pb-3">
			<Card>
				<CardHeader>
					<CardBody
						className="px-3"
						tag="h5"
						style={{ display: "flex", justifyContent: "space-between" }}
					>
						<div>
							<span style={{ color: "blue", fontWeight: "bold" }}>
								{firstName + " " + lastName}
							</span>
						</div>
						<div>
							<span style={{ color: "black" }}>Account Number: </span>
							<span style={{ color: "green", fontWeight: "bold" }}>
								{account.accountNumber}
							</span>
							( <span style={{ color: "red" }}>{account.subStatus}</span> )
							{/* {loanDate} {dealNumber} {saleType} */}
						</div>

						<div>
							{/* <span style={{ color: "black" }}>VIN: </span>
							<span style={{ color: "red" }}>{vin}</span> */}
							<span style={{ color: "black", marginLeft: "10px" }}>STOCK:</span>
							<span style={{ color: "green", fontWeight: "bold" }}>
								{account.stockNum}
							</span>
							{/* <span style={{ color: "green" }}>
								{year} {make} {model}
							</span> */}
						</div>
					</CardBody>
				</CardHeader>
			</Card>
		</div>
	);
};

export default AccountTitle;
