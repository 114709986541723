import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import shallow from "zustand/shallow";
import ChildrenModal from "components/modals/ChildrenModal";
import useCurrentDealStore from "stores/DealStore";
import { useFromUser } from "stores/LocalStorageHelper";
import { getCustomerPhoto } from "api/AttachmentsAPI";
import BuyerModal from "./BuyerModal";
import Customer700CreditTab from "features/crm/subFeatures/contactTabs/credit700Tab/Customer700CreditTab";
import Credit from "assets/img/700partner.png";
import IDPhoto from "assets/img/placeholder.png";

/**
 * BuyerHeader component
 * Displays buyer information, handles customer photo retrieval, and manages the credit pulling modal.
 *
 * @returns {JSX.Element} The BuyerHeader component.
 */
const BuyerHeader = () => {
	const [openRequestModal, setOpenRequestModal] = useState(false);
	const [profilePictureSrc, setProfilePictureSrc] = useState(IDPhoto);

	const { buyer, customerID } = useCurrentDealStore(
		(state) => ({
			buyer: state.buyer,
			customerID: state.deal.buyerRecNum,
		}),
		shallow
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	useEffect(() => {
		let isMounted = true;

		const fetchCustomerPhoto = async () => {
			if (customerID) {
				try {
					const res = await getCustomerPhoto(dealerID, locationID, customerID);
					if (isMounted && res.data && res.data.byteLength > 0) {
						const blob = new Blob([res.data]);
						const blobUrl = URL.createObjectURL(blob);
						setProfilePictureSrc(blobUrl);
					}
				} catch (err) {
					console.error("Error fetching customer photo:", err);
					if (isMounted) {
						setProfilePictureSrc(IDPhoto);
					}
				}
			} else {
				setProfilePictureSrc(IDPhoto);
			}
		};

		fetchCustomerPhoto();

		return () => {
			isMounted = false;
			// Clean up the blob URL if it exists
			if (profilePictureSrc !== IDPhoto) {
				URL.revokeObjectURL(profilePictureSrc);
			}
		};
		//eslint-disable-next-line
	}, [customerID, dealerID, locationID]);

	const toggle = () => setOpenRequestModal((prev) => !prev);

	if (!buyer?.ID) {
		return (
			<Row>
				<BuyerModal />
			</Row>
		);
	}

	return (
		<div>
			<Row>
				<div className="mx-auto w-100">
					<h4 className="text-center mb-3 mt-1">
						{`${buyer.firstName} ${buyer.lastName}`}
					</h4>
					<Row className="justify-content-center">
						<Col style={{ minHeight: "128px" }} xs="6" className="p-0">
							<img
								className="customer-profile"
								src={profilePictureSrc}
								alt="customer-profile"
								onError={() => setProfilePictureSrc(IDPhoto)}
							/>
						</Col>
						<div className="m-auto d-flex h-100 text-center flex-column justify-content-center">
							<div
								as="button"
								style={{
									maxWidth: "120px",
									margin: "auto",
									cursor: "pointer",
								}}
								onClick={toggle}
							>
								<img
									style={{ borderRadius: "2px" }}
									src={Credit}
									alt="700credit"
								/>
								<div className="d-flex align-items-center font-xs justify-content-center mt-1">
									Pull Credit <i className="nc-icon nc-minimal-right" />
								</div>
							</div>
						</div>
					</Row>
					<BuyerModal />
				</div>
			</Row>

			<ChildrenModal
				modalTitle="Credit Pulling"
				modal={openRequestModal}
				toggle={toggle}
				size="lg"
			>
				<Customer700CreditTab id={buyer?.ID} />
			</ChildrenModal>
		</div>
	);
};

export default BuyerHeader;
