import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import {
	failAlert,
	retrieveFailAlert,
	successAlertCustom,
} from "utils/alertUtils";
import { getPublicKey, processTransaction } from "api/DealerIntegrationApi";
import { useFromUser } from "stores/LocalStorageHelper";
import { showApiError } from "utils/errorRoutingUtils";

import Tokenizer from "fluidpay-tokenizer";
import { CurrencyInput } from "features/forms/components/InputElement";

const ProcessPepperPay = ({ setIsOpen }) => {
	const [tokenizer, setTokenizer] = useState({});
	const [token, setToken] = useState("");
	const [amount, setAmount] = useState(0.0);
	const locationID = useFromUser("locationID");

	const handleSubmit = () => {
		// call our backend to process transaction
		const body = {
			amount: amount * 100,
			tokenizer: token,
		};
		processTransaction(locationID, body).then(
			(res) => {
				successAlertCustom("Successfully processed transaction!");
				setIsOpen(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	const getTokenizer = async (res) => {
		// this is a script provided by pepper pay that was added to index.html
		const t = new Tokenizer({
			// pub_key retrieved from pepperpay
			apikey: res.data.content,
			// documentation says this is optional but when running locally it tries to use their local instance so we need this
			// dev environment
			// url: "https://sandbox.pepperpaygateway.com",
			url: "https://app.pepperpaygateway.com",
			container: "#pepperPayContainer",
			submission: (resp) => {
				if (resp.status === "success") {
					setToken(resp.token);
				} else {
					retrieveFailAlert("Error tokenizing request...");
				}
			},
		});
		setTokenizer(t);
	};

	useEffect(() => {
		try {
			getPublicKey(locationID).then(
				(res) => {
					getTokenizer(res);
				},
				(err) => {
					console.log(err);
					setIsOpen(false);
					retrieveFailAlert(err.response.data.message);
				}
			);
		} catch (error) {
			setIsOpen(false);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (token !== "") {
			handleSubmit();
		}
		// eslint-disable-next-line
	}, [token]);

	const handleClick = () => {
		tokenizer.submit();
	};

	return (
		<>
			<CurrencyInput
				value={amount}
				onChange={setAmount}
				wholeRow
				label="Amount"
				disableValidation
				allowNegative={false}
			/>
			<div className="col" id="pepperPayContainer"></div>
			<Button onClick={handleClick}>Submit</Button>
		</>
	);
};

export default ProcessPepperPay;
