import React from "react";
import { Button } from "reactstrap";
import { confirmDeleteMessage } from "../../utils/alertUtils";

import { SAVE } from "./NoteConstants";

/**
 * Returns a list of<table> rows <tr> from the notes.
 *
 * @param {Array} notes - Store Notes array containing objects with
 * information about each note.
 * @param {React.SetStateAction} editNotes - Setter function for the
 * Store notes field.
 * @param {React.SetStateAction} setName - Local name field state setter
 * function.
 * @param {React.SetStateAction} setLNote - Local note state setter
 * function.
 * @param {React.SetStateAction} setIndex - Local index state setter
 * function.
 * @param {React.SetStateAction} setButtonText - Local button text
 * state setter function.
 * @callback clearFields - Function to set local states to initial value
 */
const NoteListData = ({
	notes,
	editNotes,
	setLNote,
	setIndex,
	clearFields,
	setButtonText,
	disableButtons,
	saveNotes,
	toggle,
}) => {
	// Handles the onClick for the Edit button
	const handleEdit = (description, index) => {
		setButtonText(SAVE);
		setLNote(description);
		setIndex(index);
		toggle();
	};

	// Handles the onClick for the Delete button
	const handleDelete = (index) => {
		let confirmDelete = "Cancel";
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			confirmDelete = res;
			if (confirmDelete === "Yes, Delete it") {
				let arr = notes;

				// Remove the deleted object from the notes array
				arr.splice(index, 1);
				editNotes(arr);
				clearFields();
				saveNotes();
			}
		});
	};

	return notes.map((obj, index) => (
		<tr key={`${obj.createdOn}_${index}`}>
			<th scope="row">{index + 1}</th>
			<td>{obj.createdBy}</td>
			<td>{obj.createdOn}</td>
			<td>{obj.modifiedBy}</td>
			<td>{obj.modifiedOn}</td>
			<td>{obj.description}</td>
			<td>
				<Button
					readOnly={disableButtons}
					color="primary"
					className="btn-sm"
					onClick={() => handleEdit(obj.description, index)}
				>
					Edit
				</Button>
				<Button
					readOnly={disableButtons}
					color="danger"
					className="btn-sm"
					onClick={() => handleDelete(index)}
				>
					X
				</Button>
			</td>
		</tr>
	));
};

export default NoteListData;
