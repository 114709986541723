import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import ReviewVehicle from "./ReviewVehicle";
import ReviewCustomer from "./ReviewCustomer";
import ReviewCash from "./ReviewCash";
import ReviewDown from "./ReviewDown";
import ReviewFinance from "./ReviewFinance";

// Array of review components to be rendered
const components = [
	ReviewCustomer,
	ReviewCash,
	ReviewDown,
	ReviewVehicle,
	ReviewFinance,
];

/**
 * PrintReview component to render a set of review sections.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @param {object} props - The component props
 * @param {React.Ref} props.ref - The reference to the container div
 * @returns {JSX.Element} The rendered PrintReview component
 */
const PrintReview = React.forwardRef((_props, ref) => (
	<div ref={ref}>
		<Row
			style={{
				display: "flex",
				flexWrap: "wrap",
				gap: "12px",
				justifyContent: "center",
			}}
		>
			{components.map((Component, index) => (
				<Col key={index} xs="auto" style={{ padding: "0", width: "350px" }}>
					<Component />
				</Col>
			))}
		</Row>
	</div>
));

PrintReview.propTypes = {
	ref: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
};

export default PrintReview;
