import React, { useState, useRef, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Spinner,
} from "reactstrap";
import shallow from "zustand/shallow";
import {
	DC_AI_API_BASE_URL,
	StateCodeByStateName,
	smogCheck,
	titleBrands,
	vehicleStatus,
} from "constants/Constants";
import useCurrentVehicleStore from "stores/InventoryStore";
import formNavigationUtils from "utils/formNavigationUtils";
import InputElement, {
	CurrencyInputElement,
	SelectorElement,
} from "../../../../forms/components/InputElement";
import InputWrapper from "../../../../forms/components/InputWrapper";
import Swal from "sweetalert2";

const VehicleDetails = () => {
	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [descriptionLength, setDescriptionLength] = useState("short");
	const descriptionRef = useRef(null);

	// Vehicle store
	const {
		vin,
		year,
		make,
		model,
		odometerIn,
		trim,
		description,
		weightFee,
		license,
		expireDate,
		status,
		location,
		reportOfSale,
		titleNo,
		titleDateOfIssue,
		newTitleReceivedDate,
		newTitleNumber,
		titleState,
		newTitleState,
		titleInDate,
		smogCheckDate,
		isSmogCheck,
		titleBrand,
		odometerOut,
		editOdometerOut,
		editTitleBrand,
		editReportOfSale,
		editLocation,
		editDescription,
		editWeightFee,
		editLicense,
		editExpireDate,
		editTitleNo,
		editTitleDateOfIssue,
		editNewTitleReceivedDate,
		editNewTitleNumber,
		editTitleInDate,
		editTitleState,
		editNewTitleState,
		editIsSmogCheck,
		editSmogCheckDate,
	} = useCurrentVehicleStore((state) => state, shallow);
	const vehicleLocationsList = useCurrentVehicleStore(
		(state) => state.locationList
	);

	const methods = useFormContext();

	useEffect(() => {
		if (descriptionRef.current) {
			descriptionRef.current.style.height = "auto";
			descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`;
		}
	}, [description]);

	const generateDescription = async () => {
		setLoading(true);
		setModalVisible(true);

		// Construct the vehicle specs string
		const vehicleSpecs = `Year: ${year}, Make: ${make}, Model: ${model} , Trim: ${trim}, VIN: ${vin}, Mileage: ${odometerIn}`;

		// Construct the complete prompt
		const prompt = `Please generate a ${descriptionLength === "long" ? "detailed" : "brief"
			} description for this vehicle. ${descriptionLength === "long" ? "Be exactly 250 tokens." : "Be exactly 150 tokens."
			} If any value is missing or zero, please ignore it. Use emojis liberally. Here are the details: ${vehicleSpecs}`;

		const params = {
			prompt: prompt, // Send the prompt as a single string
		};

		try {
			const response = await fetch(DC_AI_API_BASE_URL, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(params),
			});

			const data = await response.json();

			if (response.ok) {
				const aiDescription = data.response;
				editDescription(aiDescription); // Update description with HTML content
			} else {
				throw new Error(data.message || "Failed to generate description");
			}
		} catch (error) {
			console.error("Error generating description from AI:", error);
			Swal.fire("Please Check Required Fields", error.message, "error");
		} finally {
			setLoading(false);
			setModalVisible(false);
		}
	};

	return (
		<Form onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle="Vehicle Details"
				inputComponents={[
					<Controller
						name="locationList"
						defaultValue={location === null || location === "" ? "0" : location}
						render={({ field }) => (
							<FormGroup className="col">
								<div>
									<Label>Location</Label>
									<CreatableSelect
										isDisabled={
											status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
										}
										value={{ value: location, label: location }}
										options={vehicleLocationsList.map((value) => ({
											value: value,
											label: value,
										}))}
										onChange={(e) => {
											console.log(e);
											editLocation(e.value);
										}}
										{...field}
									/>
								</div>
							</FormGroup>
						)}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={license}
						name="license"
						label="License Plate"
						type="text"
						onChange={(e) => {
							editLicense(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={expireDate}
						name="expireDate"
						label="License Exp. Date"
						type="date"
						onChange={(e) => {
							editExpireDate(e.target.value);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={titleInDate}
						name="titleInDate"
						label="Date Title In"
						type="date"
						onChange={(e) => {
							editTitleInDate(e.target.value);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={titleNo}
						name="titleNo"
						label="Title No"
						type="text"
						onChange={(e) => {
							editTitleNo(e.target.value);
						}}
					/>,

					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={titleState}
						name="titleStateIssue"
						label="Title State Issue"
						type="text"
						onChange={(e) => {
							editTitleState(e.target.value);
						}}
						options={StateCodeByStateName}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={newTitleReceivedDate}
						name="newTitleReceivedDate"
						label="New Title Date In"
						type="date"
						onChange={(e) => {
							editNewTitleReceivedDate(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={newTitleNumber}
						name="newTitleNumber"
						label="New Title No"
						type="text"
						onChange={(e) => {
							editNewTitleNumber(e.target.value);
						}}
					/>,
					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={newTitleState}
						name="newTitleState"
						label="New Title State Issue"
						type="text"
						onChange={(e) => {
							editNewTitleState(e.target.value);
						}}
						options={StateCodeByStateName}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={titleDateOfIssue}
						name="titleDateOfIssue"
						label="Title Date of Issue"
						type="date"
						onChange={(e) => {
							editTitleDateOfIssue(e.target.value);
						}}
					/>,
					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						name="titleBrand"
						label="Title Brand"
						value={titleBrand}
						onChange={(e) => editTitleBrand(e.target.value)}
						options={titleBrands}
					/>,

					<CurrencyInputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						value={weightFee}
						name="weightFee"
						label="Weight Fee"
						type="text"
						onChange={(e) => {
							editWeightFee(e);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={smogCheckDate}
						name="smogCheckDate"
						label="Smog Check Date"
						type="date"
						onChange={(e) => {
							editSmogCheckDate(e.target.value);
						}}
					/>,

					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						name="isSmogCheck"
						label="Smog Check"
						value={isSmogCheck}
						onChange={(e) => editIsSmogCheck(e.target.value)}
						options={smogCheck}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={reportOfSale}
						name="reportOfSale"
						label="Report Of Sale"
						type="text"
						onChange={(e) => {
							editReportOfSale(e.target.value);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={odometerOut}
						name="odometerOut"
						label="Milage Out"
						type="number"
						onChange={(e) => {
							editOdometerOut(e.target.value);
						}}
					/>,
					<Col className="p-0" xs={12}>
						<FormGroup>
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								wholeRow={true}
								value={description}
								name="description"
								label="Description"
								type="textarea"
								inputClass="invDescription"
								onChange={(e) => editDescription(e.target.value)}
								ref={descriptionRef}
							/>
							<Col>
								<FormGroup check inline>
									<Label className="p-0" check>
										<Input
											type="radio"
											name="descriptionLength"
											value="short"
											checked={descriptionLength === "short"}
											onChange={() => setDescriptionLength("short")}
											disabled={loading}
										/>{" "}
										Short
									</Label>
								</FormGroup>
								<FormGroup check inline>
									<Label className="p-0" check>
										<Input
											type="radio"
											name="descriptionLength"
											value="long"
											checked={descriptionLength === "long"}
											onChange={() => setDescriptionLength("long")}
											disabled={loading}
										/>{" "}
										Long
									</Label>
								</FormGroup>
							</Col>
							<Col>

								<Button color="primary" className="mb-2" onClick={generateDescription} disabled={loading}>
									{/* eslint-disable-next-line */}
									🤖 Generate Using DealerClick AI
								</Button>
								<p>
									** The more information you provide, the better the result will be.
								</p>
							</Col>
						</FormGroup>
					</Col>,
				]}
			/>
			<Modal isOpen={modalVisible}>
				<ModalHeader>Generating Description</ModalHeader>
				<ModalBody>
					<div className="text-center">
						<Spinner style={{ width: "3rem", height: "3rem" }} />
						<div>Processing...</div>
					</div>
				</ModalBody>
			</Modal>
		</Form>
	);
};

export default VehicleDetails;
