import React from "react";
import { Col, Row, CardText } from "reactstrap";

import shallow from "zustand/shallow";
import NumberFormat from "react-number-format";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import useCurrentAccountStore from "./AccountStore";
import {
	CurrencyDisplayElement,
	DateDisplayElement,
} from "../features/deal/components/DisplayElement";
import ReactTable from "../components/tables/ReactTable";
const AccountAmortizationTable = () => {
	const { account, amortizations } = useCurrentAccountStore(
		(state) => ({
			amortizations: state.amortizations,
			editAmortizations: state.editAmortizations,
			account: state.account,
		}),
		shallow
	);
	console.log(account);
	console.log(amortizations);

	//  int2Pay, Princ2Pay, IntPaid, PrincPaid, PropTax, PropTaxPaid, SalesTax, SalesTaxPaid, Insurance,

	const columns = React.useMemo(
		() => [
			{
				Header: "#",
				accessor: "pmtNo",
				style: { width: "20px" },
			},
			{
				Header: "Due Date",
				accessor: "dueDate",
				Cell: ({ value }) => <DateDisplayElement val={value} />,
			},
			{
				Header: "Days",
				accessor: "days",
				style: { width: "40px" },
			},
			{
				Header: " Int2Pay",
				accessor: "int2Pay",
				Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
			},
			{
				Header: " Princ2Pay",
				accessor: "princ2Pay",
				Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
			},
			{
				Header: " IntPaid",
				accessor: "intPaid",
				Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
			},
			{
				Header: " PrincPaid",
				accessor: "princPaid",
				Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
			},
			{
				Header: " SalesTax",
				accessor: "salesTax",
				Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
			},
			{
				Header: "SalesTaxPaid",
				accessor: "salesTaxPaid",
				Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
			},
		],
		[]
	);

	return (
		<>
			<Card>
				<CardBody>
					<div>
						<ReactTable data={amortizations} columns={columns} />
					</div>
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<Row>
						<Col sm="6">
							<CardText tag="h3" className="text-center">
								Amortizations Balance
							</CardText>
						</Col>
						<Col sm="6">
							<CardText tag="h3" className="text-center">
								<NumberFormat
									value={account.balance}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									isNumericString={true}
									readOnly={true}
									displayType="text"
								/>
							</CardText>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</>
	);
};
export default AccountAmortizationTable;
