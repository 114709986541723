import React, { useState } from "react";
import { Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Pepperpay from "./pepperpay/Pepperpay";
import { getDigitzsMerchant } from "api/PaymentsAPI";
import { failAlert, retrieveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { useFromPartnerLink, useFromPartnerSend, useFromUser } from "stores/LocalStorageHelper";
import Digitzs from "./digitzs/Digitzs";
import classnames from 'classnames';
import ListItemModal from "./ListItemModal";
import pepperpayLogo from "assets/img/pepperpaypartner.png";
import UAClogo from "assets/img/uac.png";
import DigitzsImg from "assets/img/digitzs-final-logo.png";
import credit700Logo from "assets/img/700partner.png";
import aulLogo from "assets/img/aulLogo.png";
import goldstandardLogo from "assets/img/goldstandardLogo.png";


const SignUps = ({ showUAC, toggleUacApi }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const useUACC = useFromPartnerSend("useUACC");
	const uacdealerID = useFromPartnerLink("uacdealerID");
	const pepperPayEmail = useFromPartnerLink("pepperPayEmail");

	const [digitzsModal, setDigitzsModal] = useState(false);
	const [pepperpayModal, setPepperpayModal] = useState(false);
	const [activeTab, setActiveTab] = useState('1');

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const toggleDigitzsModalApi = () => {
		getDigitzsMerchant(dealerID, locationID).then(
			(res) => {
				if (res.data.content !== null && res.data.content.merchantId != null) {
					failAlert("Digitzs account already exists for this dealership with merchant ID: " + res.data.content.merchantId);
					return;
				}
				setDigitzsModal(!digitzsModal);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					setDigitzsModal(!digitzsModal);
				}
			}
		);
	};

	const togglePepperpay = () => {
		setPepperpayModal(!pepperpayModal);
	};

	const toggleDigitzsModal = () => setDigitzsModal(!digitzsModal);
	const togglePepperpayModal = () => setPepperpayModal(!pepperpayModal);

	const creditLink = () => {
		window.open("https://www.700credit.com/dealerclick", "_blank", "noopener,noreferrer");
	};

	const aulLink = () => {
		window.open("https://dealerclick.com/aul.pdf", "_blank", "noopener,noreferrer");
	};

	const goldstandardLink = () => {
		window.open("https://dealerclick.com/goldstandard.pdf", "_blank", "noopener,noreferrer");
	}


	return (
		<div>
			<Nav className="tabNav">
				<NavItem>
					<NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
						<p><span aria-label="star emoji" role="img">⭐ </span>Preferred</p>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
						F&I
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
						Payments
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
						Credit
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<Row style={{ flexWrap: "wrap", justifyContent: "center" }}>
						<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3">
							<ListItemModal
								modalListTitle="700Credit"
								modalButtonTitle="Sign Up"
								toggle={creditLink}
								cardClassName="mb-2"
								imageLogo={credit700Logo}
								disabled={false}
								integrationInfo="700Credit is our preferred credit reporting solution. Easily pull credit from Equifax, TransUnion, and Experian."
							/>
						</Col>
						<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3">
							<ListItemModal
								modalListTitle="Digitzs"
								modalButtonTitle="Sign Up"
								modalTitle="Digitzs Sign Up"
								modal={digitzsModal}
								toggle={toggleDigitzsModalApi}
								cardClassName="mb-2"
								imageLogo={DigitzsImg}
								disabled={false}
								integrationInfo="Digitzs is our preferred payment processing solution. Low rates, quick transfers, and fully integrated into your system."
							>
								<Digitzs toggle={toggleDigitzsModal} />
							</ListItemModal>
						</Col>
						{showUAC && (
							<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3">
								<ListItemModal
									modalListTitle="UACC"
									modalButtonTitle="Sign Up"
									toggle={toggleUacApi}
									cardClassName="mb-2"
									imageLogo={UAClogo}
									disabled={uacdealerID !== "" && uacdealerID !== null && useUACC === 1}
									integrationInfo="UACC is one of the largest lending solutions for dealerships in the country. Sign up today to start funding deals!"
								/>
							</Col>
						)}
					</Row>
				</TabPane>
				<TabPane tabId="2">
					<Row style={{ flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
						<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3">
							<ListItemModal
								modalListTitle="AUL"
								modalButtonTitle="Sign Up"
								toggle={aulLink}
								imageLogo={aulLogo}
								disabled={false}
								integrationInfo="AUL Warranty Solutions provides a full suite of F&I products fully integrated with DealerClick."
							/>
						</Col>
						<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3">
							<ListItemModal
								modalListTitle="Goldstandard"
								modalButtonTitle="Sign Up"
								toggle={goldstandardLink}
								imageLogo={goldstandardLogo}
								disabled={false}
								integrationInfo="Goldstandard Warranty offers a comprehensive range of F&I products, seamlessly integrated with DealerClick."
							/>
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="3">
					<Row style={{ flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
						<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3">
							<ListItemModal
								modalListTitle="Digitzs"
								modalButtonTitle="Sign Up"
								modalTitle="Digitzs Sign Up"
								modal={digitzsModal}
								toggle={toggleDigitzsModalApi}
								cardClassName="mb-2"
								imageLogo={DigitzsImg}
								disabled={false}
								integrationInfo="Digitzs is our preferred payment processing solution. Low rates, quick transfers, and fully integrated into your system."
							>
								<Digitzs toggle={toggleDigitzsModal} />
							</ListItemModal>
							{pepperPayEmail !== null && (
								<ListItemModal
									modalListTitle="Pepperpay"
									modalButtonTitle="Sign Up"
									modalTitle="Pepperpay Sign Up"
									modal={pepperpayModal}
									cardClassName="mb-2"
									toggle={togglePepperpay}
									imageLogo={pepperpayLogo}
									disabled={pepperPayEmail !== "" && pepperPayEmail !== null}
									integrationInfo="Pepperpay is a payment processing solution offering low rates and an easy sign-up process."
								>
									<Pepperpay toggle={togglePepperpayModal} />
								</ListItemModal>
							)}
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="4">
					<Row style={{ flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
						<Col xs="12" sm="12" md="6" lg="6" xl="4" className="d-grid mb-3">
							<ListItemModal
								modalListTitle="700Credit"
								modalButtonTitle="Sign Up"
								toggle={creditLink}
								cardClassName="mb-2"
								imageLogo={credit700Logo}
								disabled={false}
								integrationInfo="700Credit is our preferred credit reporting solution. Easily pull credit from Equifax, TransUnion, and Experian."
							/>
						</Col>
					</Row>
				</TabPane>
			</TabContent>
		</div>
	);
};

export default SignUps;
